<template>
  <div>
    <v-dialog persistent width="400" v-model="alert.mostra">
      <v-card>
        <v-container>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            v-show="this.alert.loader"
          ></v-progress-circular>
          <v-row v-if="!alert.loader" align="center" justify="center">
            <v-col cols="1" align="center">
              <v-icon large>{{ alert.icona }}</v-icon>
            </v-col>
            <v-col cols="11">
              <h3 style="word-break: keep-all; text-align: left; padding: 0 20px">
                {{ alert.messaggio }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions v-show="!this.alert.loader">
          <v-spacer />
          <v-btn text color="primary" @click="alert.mostra = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Alert Dialog -->
    <v-dialog persistent width="400" v-model="alert.mostra">
      <v-card>
        <v-container>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            v-show="this.alert.loader"
          ></v-progress-circular>
          <v-row v-if="!alert.loader" align="center" justify="center">
            <v-col cols="1" align="center">
              <v-icon large>{{ alert.icona }}</v-icon>
            </v-col>
            <v-col cols="11">
              <h3 style="word-break: keep-all; text-align: left; padding: 0 20px">
                {{ alert.messaggio }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions v-show="!this.alert.loader">
          <v-spacer />
          <v-btn text color="primary" @click="alert.mostra = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mt-2" v-if="type == 0">
      <v-col md="4" sm="4" lg="4">
        <v-select
          @change="selectMacchinePP"
          outlined
          v-model="id_part_program"
          label="Choose Part Program"
          :items="part_program_list"
          item-text="name"
          item-value="id"
          class="ml-1 pb-0"
        >
        </v-select>
      </v-col>

      <v-btn
        @click="
          getPartProgram();
          id_part_program = null;
          singleProgram = {};
          part_program_list = [];
        "
        small
        fab
        style="float: right"
        class="mr-1 mt-5"
      >
        <v-icon color="primary">mdi-refresh</v-icon>
      </v-btn>
    </v-row>

    <!-- Part Program Card -->
    <v-card class="pt-0" v-show="singleProgram.id > 0">
      <v-toolbar dense class="indigo darken-1 headline" primary-title>
        <v-toolbar-title class="white--text">
          Part Program: {{ this.singleProgram.name }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="sendPartProgram" v-on="on" v-bind="attrs" small>
              <v-icon color="success"> mdi-send </v-icon>
            </v-btn>
          </template>
          <span>Invio part program</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="type != 0"
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              @click="getPartProgram()"
              small
            >
              <v-icon color="primary">mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Aggiornare</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" @click="Goback" small>
              <v-icon depressed class="" color="error">mdi-window-close</v-icon>
            </v-btn>
          </template>
          <span>Cancellare</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col
            md="5"
            sm="5"
            lg="5"
            v-for="(it, index) in singleProgram.steps"
            :key="index"
          >
            <DynamicInputVue
              v-model="it.default_value"
              :type="getInputType(it.type)"
              :access="it.access_type"
              :items="it.values"
              :label="it.name"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DynamicInputVue from "./DynamicInput.vue";

export default {
  components: {
    DynamicInputVue,
  },
  props: {
    id_macchina: {
      type: Number,
    },
    id_articolo_ciclo_fasi: {
      type: Number,
    },
    id_commessa_dettaglio: {
      type: Number,
    },
  },

  data() {
    return {
      // variable be used to establish type of getPartProgram call.
      id_macchina_checked: null,
      id_articolo_ciclo_fasi_checked: null,
      id_commessa_dettaglio_checked: null,
      type: null,
      //  v-select for macchine part program
      id_part_program: null,
      part_program_list: [],
      // Alert Dialog Variable
      alert: {
        icona: null,
        messaggio: null,
        mostra: false,
        loader: false,
      },

      // Single Part Program
      singleProgram: {},
    };
  },
  mounted() {
    this.getPartProgram();
  },

  methods: {
    // Function to select a pp and populate the Part program fileds
    selectMacchinePP() {
      let i = this.part_program_list.findIndex((it) => it.id == this.id_part_program);
      this.singleProgram = this.part_program_list[i];
      console.log(this.singleProgram);
    },
    // Function used to link Dynamic Input type
    getInputType(type) {
      if (type == 0) {
        return "boolean";
      }
      if (type >= 1 && type <= 11) {
        return "number";
      }
      if (type == 12) {
        return "string";
      }
      if (type == 13) {
        return "file";
      }
      return null;
    },
    setCallType() {
      // Checking if incoming Props are number
      this.id_macchina_checked = Number(this.id_macchina);
      this.id_articolo_ciclo_fasi_checked = Number(this.id_articolo_ciclo_fasi);
      this.id_commessa_dettaglio_checked = Number(this.id_commessa_dettaglio);
      // Determining the type of get call
      // First check is for Macchine if it 0
      if (
        !isNaN(this.id_macchina_checked) &&
        isNaN(this.id_articolo_ciclo_fasi_checked) &&
        isNaN(this.id_commessa_dettaglio_checked)
      ) {
        this.type = 0;
      }
      // Second check for type=1 which is article
      if (
        !isNaN(this.id_macchina_checked) &&
        !isNaN(this.id_articolo_ciclo_fasi_checked) &&
        isNaN(this.id_commessa_dettaglio_checked)
      ) {
        this.type = 1;
      }
      // Third check for type=2 which is Commessa
      if (
        !isNaN(this.id_macchina_checked) &&
        isNaN(this.id_articolo_ciclo_fasi_checked) &&
        !isNaN(this.id_commessa_dettaglio_checked)
      ) {
        this.type = 2;
      }
    },
    // Function to get Part program in avanzamento
    getPartProgram() {
      this.alert.loader = true;
      this.alert.mostra = true;
      this.setCallType();
      console.log(this.type);
      let request = {
        controller: "quickConnext",
        method: "POST",
        richiesta: {
          action: "getassociatedpartprogramparameters",
          token: localStorage.getItem("user_token"),
          call_type: this.type,
          id_macchina: this.id_macchina_checked,
        },
      };
      if (this.type == 1) {
        request.richiesta.id_articolo_ciclo_fasi = this.id_articolo_ciclo_fasi_checked;
      }
      if (this.type == 2) {
        request.richiesta.id_commessa_dettaglio = this.id_commessa_dettaglio_checked;
      }

      this.$store.dispatch("api", request).then((res) => {
        if (this.type === 0) {
          this.part_program_list = res.data;
        } else {
          this.singleProgram = res.data;
        }
        this.alert.mostra = false;
        this.alert.loader = false;
      });
    },

    // Function to send Part Program from avanzamento
    sendPartProgram() {
      for (let index = 0; index < this.singleProgram.steps.length; index++) {
        const step = this.singleProgram.steps[index];
        if (
          step.default_value === "" ||
          step.default_value === undefined ||
          step.default_value === null
        ) {
          Object.assign(this.alert, {
            icona: "mdi-alert-box",
            messaggio: `${step.name} should not be empty`,
            mostra: true,
          });
          return;
        }
        if (step.type >= 2 && step.type <= 11) {
          if (Number(step.default_value) < Number(step.boundaries.hard_min_value)) {
            Object.assign(this.alert, {
              icona: "mdi-alert-box",
              messaggio: `${step.name} should be greater than min value ${Number(
                step.boundaries.hard_min_value
              )}`,
              mostra: true,
            });
            return;
          } else if (
            Number(step.default_value) > Number(step.boundaries.hard_max_value)
          ) {
            Object.assign(this.alert, {
              icona: "mdi-alert-box",
              messaggio: `${step.name} should be less than max value ${Number(
                step.boundaries.hard_max_value
              )}`,
              mostra: true,
            });
            return;
          }
        }
      }
      let request = {
        controller: "quickConnext",
        method: "PUT",
        richiesta: {
          action: "sendpartprogram",
          token: localStorage.getItem("user_token"),
          call_type: this.type,
          id_macchina: this.id_macchina_checked,
          part_program: this.singleProgram,
        },
      };
      console.log(`this.singleProgram`, this.singleProgram);
      this.setCallType();
      console.log(this.type);
      if (this.type == 1) {
        request.richiesta.id_articolo_dettaglio_4_0 = this.singleProgram.id_articolo_dettaglio_4_0;
      }
      // if (this.type == 2) {
      //   request.richiesta.id_commessa_dettaglio_4_0 = this.id_commessa_dettaglio_checked;
      // }
      this.$store.dispatch("api", request).then((res) => {
        console.log(res.data);
      });
    },

    Goback() {
      this.$emit("GoBack");
    },
  },
};
</script>
