<template>
  <v-dialog persistent hide-overlay v-model="dialog" width="1100">
    <template v-slot:activator="{}">
      <Button
        colore_icona="primary"
        idForm="MACCHINA_SCHEDA"
        idOggetto="CREA_MACCHINA_SCHEDA"
        mdi_icona="mdi-plus"
        testo_tooltip="Crea Scheda"
        v-on:buttonClick="handleSeen"
      >
      </Button>
    </template>
    <v-card>
      <v-toolbar dense class="indigo darken-1 headline" primary-title>
        <v-toolbar-title class="white--text">Aggiungi Scheda</v-toolbar-title>
        <v-spacer></v-spacer>
        <Button
          colore_icona="success"
          idForm="MACCHINA_SCHEDA"
          idOggetto="SALVA_MACCHINA_SCHEDA"
          mdi_icona="mdi-floppy"
          testo_tooltip="Salva"
          v-on:buttonClick="
            dialog = false;
            onSubmit();
          "
        >
        </Button>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="dialog = false" small v-on="on" v-bind="attrs">
              <v-icon depressed color="error">mdi-window-close</v-icon>
            </v-btn>
          </template>
          <span>Cancellare</span>
        </v-tooltip>
      </v-toolbar>
      <v-form>
        <v-container>
          <v-row class="">
            <v-col class="py-0" cols="4" sm="2" md="2">
              <TextField
                idForm="MACCHINA_SCHEDA"
                idOggetto="CREA_SCHEDA_NUM_OPERAZIONE"
                label_input="Num Operazione"
                placeholder_input="Num Operazione"
                :vmodel="num_operazione"
                :min_input="0"
                type_input="number"
                v-on:changeText="(val) => (this.num_operazione = val)"
              />
            </v-col>
            <v-col sm="2" class="py-0" cols="4" md="2">
              <v-select
                hide-details="true"
                item-value="id_tabella"
                v-model="id_tipo_operazione"
                :items="tipo_operazione_list"
                item-text="des"
                return-object
                label="Tipo Operazioni"
                required
              ></v-select>
            </v-col>
            <v-col cols="4" class="py-0" sm="2" md="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="dumFormat"
                    label="D.u.m"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data_ultima_manutenzione"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex py-0" sm="2" cols="4" md="2">
              <v-select
                v-model="id_calcolo"
                :items="calcolo_list"
                item-value="id_tabella"
                item-text="des"
                label="Calcolo"
                return-object
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex py-0" sm="2" cols="4" md="2">
              <v-select
                v-model="id_metodo_calcolo"
                item-value="id_tabella"
                return-object
                :items="metodo_calcolo_list"
                item-text="des"
                label="Metodo Calcolo"
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex py-0" cols="4" sm="2" md="2">
              <TextField
                idForm="MACCHINA_SCHEDA"
                idOggetto="CREA_SCHEDA_ORE_GIORNI"
                label_input="Ore giorni"
                placeholder_input="Ore giorni"
                :vmodel="ore_giorni"
                :min_input="0"
                type_input="number"
                v-on:changeText="(val) => (this.ore_giorni = val)"
              />
            </v-col>
            <v-col class="d-flex py-0 shrink" sm="3" cols="4" md="2">
              <v-select
                v-model="id_responsabile"
                return-object
                item-value="id_tabella"
                :items="responsabile_list"
                item-text="des"
                label="Responsabile"
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex py-0" sm="4" cols="4" md="9">
              <TextField
                idForm="MACCHINA_SCHEDA"
                idOggetto="CREA_SCHEDA_DES"
                label_input="Descrizione Scheda"
                placeholder_input="Descrizione Scheda"
                :vmodel="des_scheda"
                v-on:changeText="(val) => (this.des_scheda = val)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField.vue";
// import config from '../../../../public/config'
export default {
  // Data shared by Parent component
  props: ["orderDetail"],
  components: {
    Button,
    TextField,
    //  Modules: () => import("@/components/TOOLS/Modules"),
  },
  data() {
    return {
      // Variable for Snackbar
      // All the data variables required for the Add scheda Modal
      responsabile_list_Id: [],
      tipo_operazione_list: [],
      calcolo_list: [],
      metodo_calcolo_list: [],
      responsabile_list: [],
      additional_items: [],
      reparto_detail: "",
      seen: false,
      // id_macchina: null,
      num_operazione: "",
      id_tipo_operazione: "",
      scadenza: 0,
      ore_giorni: null,
      dialog: false,
      id_calcolo: "",
      id_metodo_calcolo: "",
      id_responsabile: "",
      des_scheda: "",
      id_utente: localStorage.id,
      data_ultima_manutenzione: new Date().toISOString().substr(0, 10),
      validita: 0,
      date_ins: new Date().toISOString().substr(0, 10),
      date_mod: new Date().toISOString().substr(0, 10),
      objectDetail: {},
      menu2: false,
      menu3: false,
      menu4: false,
      // show: true
    };
  },
  watch: {
    orderDetail() {
      const or = this.orderDetail;
      this[or] = !this[or];
    },
  },
  methods: {
    handleSeen() {
      this.dialog = true;
    },
    // In order to add a new Scheda
    onSubmit() {
      if (this.num_operazione === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Il campo Num Operazione è richiesto!";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.id_tipo_operazione === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Il campo Tipo Operazione è richiesto!";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.id_metodo_calcolo === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Il campo Metodo Articolo è richiesto!";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.ore_giorni === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Il campo Ore Giorni è richiesto!";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.id_calcolo === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Il campo Calcolo è richiesto!";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.des_scheda === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Il campo Des Scheda è richiesto!";
        this.snackbar_text_color = "white";
        return;
      }
      let schedadata = {
        date_ins: this.date_ins,
        date_mod: this.date_mod,
        id_macchina: this.orderDetail.macchina[0].id_macchina,
        data_ultima_manutenzione: this.data_ultima_manutenzione,
        des_scheda: this.des_scheda,
        id_calcolo: this.id_calcolo.id_tabella,
        id_metodo_calcolo: this.id_metodo_calcolo.id_tabella,
        id_responsabile: this.id_responsabile.id_tabella,
        id_tipo_operazione: this.id_tipo_operazione.id_tabella,
        des_calcolo: this.id_calcolo.des,
        des_metodo_calcolo: this.id_metodo_calcolo.des,
        des_responsabile: this.id_responsabile.des,
        des_tipo_operazione: this.id_tipo_operazione.des,
        num_operazione: this.num_operazione,
        ore_giorni: this.ore_giorni,
        scadenza: this.scadenza,
        validita: 1,
        id_macchina_scheda_ordinaria: -1,
      };
      let updateLocale = schedadata;
      let dataarray = [];
      dataarray.push(updateLocale);
      let finale = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "updatescheda",
          token: localStorage.getItem("user_token"),
          tabella: dataarray,
        },
      };
      this.$store.dispatch("api", finale).then((res) => {
        /* Faccio una chiamata setmanutenzione per assegnare
         * l'esito di default alla scheda appena creata. */
        if (res.status === 200) {
          let obj_tmp = Object.assign(updateLocale);
          obj_tmp.id_macchina_scheda_ordinaria = res.data;
          obj_tmp.id_macchina_manutenzione_ordinaria = this.orderDetail
            .macchina[0].id_macchina_manutenzione_ordinaria
            ? this.orderDetail.macchina[0].id_macchina_manutenzione_ordinaria
            : -1;
          obj_tmp.esito = this.id_esito_scheduled;
          let setManutenzione = {
            controller: "Manutenzione",
            method: "PUT",
            richiesta: {
              action: "setmanutenzione",
              token: localStorage.getItem("user_token"),
              tabella: [obj_tmp],
            },
          };
          this.$store.dispatch("api", setManutenzione);
          //add an emit for force reload father component
          this.$emit("aggiornaScheda");
          this.onClear();
        }
      });
    },
    // TO make the Form fields empty
    onClear() {
      this.objectDetail = "";
      this.data_ultima_manutenzione = "";
      this.des_scheda = "";
      this.id_macchina = "";
      this.id_calcolo = "";
      this.id_macchina = "";
      this.id_metodo_calcolo = "";
      this.id_responsabile = "";
      this.id_tipo_operazione = "";
      this.num_operazione = "";
      this.ore_giorni = "";
      this.scadenza = "";
    },
  },
  computed: {
    dumFormat() {
      return this.data_ultima_manutenzione
        ? moment(this.data_ultima_manutenzione).format("DD/MM/YYYY")
        : "";
    },
  },
  mounted() {
    // In order to get list of data for select fields
    let req_data = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo:
          "'MANUTENZIONE_TIPO_OPERAZIONE','MANUTENZIONE_METODO_CALCOLO','MANUTENZIONE_CALCOLO','MANUTENZIONE_RESPONSABILE','MAN_ESITO'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", req_data).then((res) => {
      this.metodo_calcolo_list = res.data.MANUTENZIONE_METODO_CALCOLO;
      this.tipo_operazione_list = res.data.MANUTENZIONE_TIPO_OPERAZIONE;
      this.calcolo_list = res.data.MANUTENZIONE_CALCOLO;
      this.responsabile_list = res.data.MANUTENZIONE_RESPONSABILE;

      if (res.data.MAN_ESITO) {
        res.data.MAN_ESITO.forEach((elem) => {
          if (elem.cod == "SCHEDULED") {
            this.id_esito_scheduled = elem.id_tabella;
          }
        });
      }
    });
  },
};
</script>
<style lang="scss">
.v-dialog {
  overflow-x: hidden !important;
}
.vehDialog {
  overflow: hidden !important;
}
</style>
