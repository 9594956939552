<template>
  <div class="main pt-3">
    <!-- Dialog to Add new File to Articolo -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogAggiungiAllegato"
      width="500"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Aggiungi Allegati all'Macchina</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="setImmagine()" small>
                <v-icon v-on="on" color="success" v-bind="attrs">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-n4" @click="dialogAggiungiAllegato = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="allegato"
            @paste="onPaste"
            accept="image/x-png,image/gif,image/jpeg,image/jpg,image,image/PNG"
            label="File"
            prepend-icon="mdi-paperclip"
            @click:prepend="openImagePicker"
          ></v-text-field>
          <input
            type="file"
            v-on:change="onFileChange"
            ref="allegato"
            name="allegato"
            class="d-none"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Expandable -->
    <v-card class="mx-3">
      <v-toolbar dense class="indigo darken-1 headline" primary-title>
        <v-toolbar-title class="white--text">Impianto Dettaglio</v-toolbar-title>
        <v-spacer></v-spacer>
        <Button
          colore_icona="success"
          idForm="MACCHINA"
          idOggetto="SALVA_MACCHINA"
          mdi_icona="mdi-floppy"
          stile_icona="float: left"
          testo_tooltip="Salva Macchina"
          v-on:buttonClick="salvaMacchina"
        >
        </Button>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              style="color: #f44336 !important"
              v-bind="attrs"
              @click="goMacchina"
              class=""
              small
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span> Indietro</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <v-expansion-panels style="opacity: 0.9; width: 90vw" class="expansion_panel">
          <v-expansion-panel
            style="opacity: 0.9; outline: 0"
            v-for="(order, index) in this.macchina_detail.macchina"
            :key="index"
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col md="1" sm="3" class="py-0" cols="2">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_ID_MACCHINA"
                    label_input="Macchina"
                    palceholder_input="Macchina"
                    :vmodel="order.id_macchina"
                    :readonly="true"
                  />
                </v-col>
                <v-col md="2" sm="3" class="py-0" cols="3">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_CODICE_MACCHINA"
                    label_input="Cod. Macchina"
                    palceholder_input="Cod. Macchina"
                    :vmodel="order.cod_macchina"
                    v-on:changeText="
                      (val) => (macchina_detail.macchina[0].cod_macchina = val)
                    "
                  />
                </v-col>
                <v-col md="2" sm="3" class="py-0" cols="3">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_MATRICOLA_MACCHINA"
                    label_input="Matricola"
                    palceholder_input="Matricola"
                    :vmodel="order.matricola"
                    v-on:changeText="
                      (val) => (macchina_detail.macchina[0].matricola = val)
                    "
                  />
                </v-col>
                <v-col md="2" sm="3" class="py-0" cols="3">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_MARCA_MACCHINA"
                    label_input="Marca"
                    palceholder_input="Marca"
                    :vmodel="order.marca"
                    v-on:changeText="(val) => (macchina_detail.macchina[0].marca = val)"
                  />
                </v-col>
                <v-col md="5" sm="3" class="py-0" cols="2">
                  <TextField
                    class="mr-6"
                    idForm="MACCHINA"
                    idOggetto="CREA_DES_MACCHINA"
                    label_input="Descrizione"
                    palceholder_input="Descrizione"
                    :vmodel="order.des_macchina"
                    v-on:changeText="
                      (val) => (macchina_detail.macchina[0].des_macchina = val)
                    "
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <!-- Expanansion Content-->

            <v-expansion-panel-content>
              <v-row>
                <v-col md="2" sm="3" class="py-0" cols="3">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_ORA_DISPONIBILITA_MACCHINA"
                    label_input="Ore. disponibilita"
                    palceholder_input="Ore. disponibilita"
                    :vmodel="macchina_detail.macchina[0].ore_disponibilita"
                    v-on:changeText="
                      (val) => (macchina_detail.macchina[0].ore_disponibilita = val)
                    "
                  />
                </v-col>
                <v-col class="py-0" cols="4" md="1" sm="3">
                  <div class="mt-n1">
                    <span style="font-size: 12px; color: rgba(0, 0, 0, 0.6)"
                      >Autonoma</span
                    >
                    <v-switch
                      v-model="macchina_detail.macchina[0].autonoma"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1 ml-2"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col class="py-0" cols="3" md="1" sm="3">
                  <div class="ml-2 mt-n1">
                    <span
                      style="
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.6);
                        margin-left: -2rem;
                      "
                      >Mutli. Commessa</span
                    >
                    <v-switch
                      v-model="macchina_detail.macchina[0].multi_commessa"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col class="py-0" cols="3" md="1" sm="3">
                  <div class="ml-1 mt-n1">
                    <span
                      style="
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.6);
                        margin-left: -2.9rem;
                        padding-left: -0.2rem;
                      "
                      >OnLine</span
                    >
                    <v-switch
                      v-model="macchina_detail.macchina[0].online"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1 ml-n2"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col class="py-0" cols="3" md="1" sm="3">
                  <div class="ml-1 mt-n1">
                    <span
                      style="
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.6);
                        margin-left: -2.9rem;
                        padding-left: -0.2rem;
                      "
                      >Tipo. Connessione</span
                    >
                    <v-switch
                      v-model="macchina_detail.macchina[0].tipo_connessione"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1 ml-n2"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="1" class="py-0">
                  <div class="ml-1 mt-n1">
                    <span
                      style="
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.6);
                        margin-left: -2.9rem;
                        padding-left: -0.2rem;
                      "
                      >4.0</span
                    >
                    <v-switch
                      v-model="macchina_detail.macchina[0].flag_4_0"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1 ml-n2"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col class="py-0" cols="3" md="2" sm="3">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_DATA_MACCHINA"
                    label_input="Data Acquisto"
                    palceholder_input="Data Acquisto"
                    :vmodel="macchina_detail.macchina[0].data_acquisto"
                    :readonly="true"
                  />
                </v-col>
                <v-col class="py-0" cols="3" md="2" sm="3">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_SPECIFICHE_MACCHINA"
                    label_input="Specifiche"
                    palceholder_input="Specifiche"
                    :vmodel="macchina_detail.macchina[0].specifiche"
                    v-on:changeText="
                      (val) => (macchina_detail.macchina[0].specifiche = val)
                    "
                  />
                </v-col>
                <v-col class="py-0" cols="3" md="2" sm="3">
                  <v-autocomplete
                    v-model="macchina_detail.macchina[0].id_stato"
                    :items="des_stato_list"
                    item-text="des"
                    label="Stato"
                    :placeholder="macchina_detail.macchina[0].des_stato"
                    required
                    item-value="id_tabella"
                  ></v-autocomplete>
                </v-col>
                <v-col class="py-0" cols="3" md="2" sm="3">
                  <v-autocomplete
                    v-model="macchina_detail.macchina[0].id_funzione"
                    :items="des_funzione_list"
                    item-text="des"
                    label="Funzione"
                    :placeholder="macchina_detail.macchina[0].des_funzione"
                    required
                    item-value="id_tabella"
                  ></v-autocomplete>
                </v-col>
                <v-col class="py-0" cols="3" md="2" sm="3">
                  <v-autocomplete
                    v-model="macchina_detail.macchina[0].id_reparto"
                    :items="des_reparto_list"
                    item-text="des"
                    label="Reparto"
                    :placeholder="macchina_detail.macchina[0].des_reparto"
                    required
                    item-value="id_tabella"
                  ></v-autocomplete>
                </v-col>
                <v-col class="py-0" cols="3" md="2" sm="3">
                  <v-autocomplete
                    v-model="macchina_detail.macchina[0].id_centrocosto"
                    :items="des_controcosto_list"
                    item-text="des"
                    label="CentroCosto"
                    :placeholder="macchina_detail.macchina[0].des_centrocosto"
                    required
                    item-value="id_tabella"
                  ></v-autocomplete>
                </v-col>
                <v-col class="py-0" cols="3" md="1" sm="3">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_DES_CONNESSIONE_MACCHINA"
                    label_input="Des. Connessione"
                    palceholder_input="Des. Connessione"
                    :vmodel="macchina_detail.macchina[0].des_connessione"
                    v-on:changeText="
                      (val) => (macchina_detail.macchina[0].des_connessione = val)
                    "
                  />
                </v-col>

                <v-col class="py-0" cols="12" md="3">
                  <v-row>
                    <v-col md="2" cols="4">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-5"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogAggiungiAllegato = true"
                          >
                            <v-icon color="#1a80b6">mdi-image</v-icon>
                          </v-btn>
                        </template>
                        <span>Nuova Immagine dell'Macchina</span>
                      </v-tooltip>
                    </v-col>
                    <v-col md="8" cols="12" class="d-flex justify-center">
                      <div
                        v-if="!isPdf(immagine_preview)"
                        v-viewer
                        style="top: 20px"
                        class="images clearfix"
                      >
                        <img
                          id="immagine"
                          style="
                            height: 100% !important;
                            width: 100%;
                            display: block !important;
                            margin-left: 3rem !important; ;
                          "
                          :src="immagine_preview"
                          :lazy-src="immagine_preview"
                          aspect-ratio="1"
                          class="grey lighten-2 mt-n3"
                        />
                      </div>
                      <v-card
                        @click="openPdfAppenaCaricato(immagine_preview)"
                        style="height: 40px !important; width: 80px !important"
                        v-if="isPdf(immagine_preview)"
                      >
                        <div style="text-align: center">
                          <v-spacer></v-spacer>
                          <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <!-- In order to ALert of This Page -->
      <v-snackbar
        content-class="snackbar"
        :color="snackbar_background"
        right
        v-model="snackbar"
        :timeout="3000"
      >
        <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
        <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
          {{ snackbar_text }}
        </h2>
        <v-btn
          @click="snackbar = false"
          fab
          dark
          text
          small
          style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
        >
          <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
        </v-btn>
      </v-snackbar>

      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab
          active-class="sfondoStandard"
          class="py-0 my-0"
          v-for="item in [
            'Manutenzione Ordinaria',
            'Manutenzione Straordinaria',
            'Struttura Impianto',
            'Ricambi',
          ]"
          :key="item"
        >
          {{ item }}
        </v-tab>
        <v-tab
          v-show="macchina_detail.macchina[0].flag_4_0"
          active-class="sfondoStandard"
          class="py-0 my-0"
        >
          Part program
        </v-tab>
        <v-tab
          v-show="macchina_detail.macchina[0].flag_4_0"
          active-class="sfondoStandard"
          class="py-0 my-0"
        >
          Industria 4.0
        </v-tab>
        <v-tab
          v-show="macchina_detail.macchina[0].flag_4_0"
          active-class="sfondoStandard"
          class="py-0 my-0"
        >
          Part Program config
        </v-tab>
      </v-tabs>
      <v-tabs-items class="my-0 pb-3" v-model="tab">
        <v-tab-item>
          <!-- Child Component for showing the Scheda Of One Macchina -->
          <SchedaMantinance
            @updateScheda="
              getSchedaMacchina();
              getMacchineDistintaBase();
              getimageMacchina();
            "
            v-on:rowClick="
              dataChaged($event);
              onSchedaClick($event);
            "
            :schedas="this.macchina_detail.macchina_scheda_ordinaria"
            :macchinaDetail="this.macchina_detail"
            :key="this.macchina_detail.macchina[0].id_macchina"
          />
        </v-tab-item>
        <v-tab-item>
          <!-- Child Component for showing the Scheda Of One Macchina -->
          <SchedaMantinanceStraordinaria
            v-on:rowClick="
              dataChaged($event);
              onSchedaClick($event);
            "
            :schedas="this.macchina_detail.macchina_scheda_ordinaria"
            :macchinaDetail="this.macchina_detail"
            :key="this.macchina_detail.macchina[0].id_macchina"
          />
        </v-tab-item>
        <v-tab-item>
          <v-card :elevation="8" class="ml-3 mr-3">
            <v-toolbar primary-title dense class="indigo darken-1 rounded-t my-0 py-0">
              <v-toolbar-title class="white--text"
                >Componenti dell'Impianto</v-toolbar-title
              >
              <!-- <v-spacer></v-spacer>
              <v-btn
                v-if="!on"
                @click="showIcons"
                class="ml-16"
                color="white"
                dark
                fab
                top
                tile
                small
              >
                <v-icon color="primary">mdi-arrow-collapse</v-icon>
              </v-btn>
              <v-btn
                v-if="on"
                dark
                fab
                top
                tile
                @click="hideIcons"
                class="ml-16"
                color="white"
                small
              >
                <v-icon color="primary">mdi-arrow-expand</v-icon>
              </v-btn> -->
            </v-toolbar>
            <v-card-text class="text-left" style="overflow: auto; max-height: 600px">
              <v-treeview
                activatable
                shaped
                hoverable
                :open.sync="open"
                :items="arrayItemsTreeview"
                item-key="id"
                content="6"
              >
                <!-- open-on-click -->
                <template v-slot:prepend="{ item, open }">
                  <!-- <v-icon v-if="item.id_macchina == 0">{{
                    open ? "mdi-buffer" : "mdi-buffer"
                  }}</v-icon> -->
                  <v-icon v-if="item.id_macchina > 0">{{
                    open ? "mdi-text-short" : "mdi-text-short"
                  }}</v-icon>
                  <template v-if="item.id_macchina >= -1">
                    {{ item.cod_macchina }}: {{ item.des_macchina }}
                  </template>
                </template>
                <template v-if="hideicons === true" slot="append" slot-scope="{ item }">
                  <Button
                    v-if="!item.id_impianto"
                    colore_icona="primary"
                    idForm="ARTICOLO_DISTINTA_BASE"
                    idOggetto="CREA_ARTICOLO_DISTINTABASE"
                    mdi_icona="mdi-plus"
                    testo_tooltip="Inserisci Componente"
                    v-on:buttonClick="clickAggiungiChild(item)"
                  />
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        @click="clickEliminaFiglio(item)"
                        small
                      >
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-btn>
                    </template>
                    <span>Elimina Componente</span>
                  </v-tooltip>

                  <!-- <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="getDetail(item)"
                        v-on="on"
                        v-if="item.id_rif == 0"
                        small
                      >
                        <v-icon color="blue">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Dettaglio Componente</span>
                  </v-tooltip> -->
                  <!-- <Button
                    classe_bottone="ml-1"
                    v-if="item.id_rif == 0"
                    colore_icona="primary"
                    idForm="ARTICOLO_DISTINTA_BASE"
                    idOggetto="MODIFICA_ARTICOLO_DISTINTABASE"
                    mdi_icona="mdi-pencil"
                    testo_tooltip="Modifica Componente"
                    v-on:buttonClick="updateItem(item)"
                  />
                  <Button
                    classe_bottone="ml-1"
                    v-if="item.id_rif == 0"
                    colore_icona="error"
                    idForm="ARTICOLO_DISTINTA_BASE"
                    idOggetto="ELIMINA_ARTICOLO_DISTINTABASE"
                    mdi_icona="mdi-window-close"
                    testo_tooltip="Cancella Componente"
                    v-on:buttonClick="selectDistinta(item)"
                  /> -->
                </template>
              </v-treeview>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card :elevation="8" class="ml-3 mr-3">
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text">Ricambi Impianto</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="clickAggiungiRicambio" small v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi Ricambio</span>
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-data-table :headers="headers_ricambi" :items="items_ricambi">
              <template v-slot:[`item.edita`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      @click="clickModificaRicambio(item)"
                    >
                      <v-icon color="primary"> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica Quantità</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.immagine_preview`]="{ item }">
                <div
                  :id="item.id_ricambi"
                  style="
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    border: 1px solid gray;
                    border-radius: 3px;
                    height: 100px;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    width: 100px;
                  "
                ></div>
              </template>
              <template v-slot:[`item.elimina`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      @click="clickEliminaRicambio(item)"
                    >
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina Ricambio</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card :elevation="8" class="ml-3 mr-3">
            <MacchinaProgamma :key="id_macchina" :id_macchina="Number(id_macchina)" />
          </v-card>
        </v-tab-item>
        <!-- <v-tab-item>
          <v-card :elevation="8" class="ml-3 mr-3">
            <MacchinaProtocolo :key="id_macchina" :id_macchina="id_macchina" />
          </v-card>
        </v-tab-item> -->
        <v-tab-item>
          <v-card :elevation="8" class="ml-3 mr-3">
            <industry-4-vue :key="id_macchina" :id_macchina="id_macchina" />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card :elevation="8" class="ml-3 mr-3">
            <MacchinaProgammaConfig :key="id_macchina" :id_macchina="id_macchina" />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card :elevation="8" class="ml-3 mr-3">
            <PartProgramSender :key="id_macchina" :id="id_macchina" tipo="macchina" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>

    <!-- DIALOG AGGIUNGI COMPONENTE NELL ALBERO  -->
    <v-dialog v-model="dialog_aggiungi_child" width="800" persistent>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Aggiungi Componente</v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- Bottone di indietro -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on" @click="chiudiInserisciComponente"
                ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
              >
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
        <v-row align="center" justify="center">
          <v-col cols="4">
            <v-text-field label="Codice Macchina" v-model="macchinaSearch"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Descrizione Macchina" v-model="desSearch"></v-text-field>
          </v-col>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickLenteMacchina"
                class="mr-2"
                v-on="on"
                v-bind="attrs"
                small
              >
                <v-icon color="primary">mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Cerca Macchina</span>
          </v-tooltip>
        </v-row>
        <v-data-table
          dense
          :headers="headers_macchine"
          :items-per-page="7"
          :items="macchine_lista"
        >
          <template v-slot:item="{ item }">
            <tr @click="clickSelezionaArticolo(item)">
              <td
                v-for="(colonna, i) in headers_macchine"
                :key="i"
                v-show="colonna.value != null"
                class="text-left"
              >
                {{ item[colonna.value] }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_conferma_selezione" width="unset" persistent>
      <v-card>
        <v-toolbar class="white--text" color="indigo" dense>
          <v-toolbar-title>Conferma</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          Vuoi inserire la macchina:
          <b>{{ macchina_tmp ? macchina_tmp.des_macchina : "" }}</b>
          all'impianto:
          <b>{{ macchina_sel ? macchina_sel.cod_macchina : "" }}</b>
          ?
        </v-card-text>
        <v-divider></v-divider>
        <v-btn class="mx-2 my-2" @click="closeSelezionaArticoloTree" small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" @click="selezionaMacchina" small>
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- DIALOG AGGIUNGI RICAMBIO -->
    <v-dialog v-model="dialog_aggiungi_ricambio" width="800">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Aggiungi Ricambio</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on" @click="clickIndietroAggiungiRicambio"
                ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
              >
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>

        <v-row align="center" justify="center">
          <v-col cols="4">
            <v-text-field label="Codice Articolo" v-model="articoloSearch"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Descrizione Articolo"
              v-model="desArticoloSearch"
            ></v-text-field>
          </v-col>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickLenteRicambio"
                class="mr-2"
                v-on="on"
                v-bind="attrs"
                small
              >
                <v-icon color="primary">mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Cerca Ricambio</span>
          </v-tooltip>
        </v-row>

        <v-data-table
          dense
          :headers="headers_ricambi_articolo"
          :items-per-page="7"
          :items="items_ricambi_articoli"
        >
          <template v-slot:item="{ item }">
            <tr @click="clickSelezionaRicambio(item)">
              <td
                v-for="(colonna, i) in headers_ricambi_articolo"
                :key="i"
                v-show="colonna.value != null"
                class="text-left"
              >
                {{ item[colonna.value] }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- DIALOG CONFERMA QUANTITA -->
    <v-dialog v-model="dialog_conferma_sel_ricambio" width="300">
      <v-card>
        <v-toolbar class="white--text" color="indigo" dense>
          <v-toolbar-title>Seleziona Quantità</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5"
          >Inserisci la quantità di articoli:
          <b>
            {{ ricambio_sel != null ? ricambio_sel.cod_articolo : "" }}
          </b>
          necessari per questo impianto.
          <v-row justify="center">
            <v-col cols="6">
              <v-text-field
                type="number"
                label="Quantità"
                min="0"
                v-model="quantita"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-btn class="mx-2 my-2" @click="chiudiSelezionaQuantita" small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" @click="confermaSelezioneRicambio" small>
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- DIALOG CONFERMA FINALE -->
    <v-dialog v-model="conferma_finale_ricambio" width="300">
      <v-card>
        <v-toolbar class="white--text" color="indigo" dense>
          <v-toolbar-title>Conferma</v-toolbar-title>
        </v-toolbar>

        <v-row class="mt-2">
          <v-card-text>
            Vuoi aggiungere
            <b>{{ quantita }}</b>
          </v-card-text>
        </v-row>
        <v-row class="py-0 my-0">
          <v-card-text class="py-0 my-0 mb-2">
            ricambio:
            <b>{{ ricambio_sel != null ? ricambio_sel.cod_articolo : "" }}</b>
            per questo impianto?
          </v-card-text>
        </v-row>

        <v-divider></v-divider>
        <v-btn class="mx-2 my-2" @click="chiudiConfermaRicambio" small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" @click="confermaFinaleRicambio" small>
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- DIALOG MODIFICA RICAMBIO -->
    <v-dialog v-model="dialog_modifica_qta_ricambio" width="300">
      <v-card>
        <v-toolbar class="white--text" color="indigo" dense>
          <v-toolbar-title>Modifica Quantità</v-toolbar-title>
        </v-toolbar>

        <v-row class="mt-2">
          <v-card-text>
            Stai modificando la quantità del ricambio:
            <b>
              {{
                ricambio_modifica_sel != null ? ricambio_modifica_sel.cod_articolo : ""
              }}
            </b>
          </v-card-text>
        </v-row>
        <v-row class="py-0 my-0">
          <v-card-text class="py-0 my-0 mb-2">
            Nuova quantità:
            <v-row justify="center">
              <v-col cols="6">
                <v-text-field
                  type="number"
                  label="Quantità"
                  min="1"
                  v-model="quantita"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-row>

        <v-divider></v-divider>
        <v-btn class="mx-2 my-2" @click="chiudiModifica" small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" @click="confermaModificaRicambio" small>
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <!-- DIALOG elimina RICAMBIO -->
    <v-dialog v-model="dialog_elimina_ricambio" width="300">
      <v-card>
        <v-toolbar class="white--text" color="indigo" dense>
          <v-toolbar-title>Conferma Eliminazione</v-toolbar-title>
        </v-toolbar>
        <v-row class="mt-2 mb-2">
          <v-card-text>
            Vuoi elimirare il ricambio:
            {{ ricambio_modifica_sel ? ricambio_modifica_sel.cod_articolo : "" }}
          </v-card-text>
        </v-row>

        <v-divider></v-divider>
        <v-btn class="mx-2 my-2" @click="chiudiElimina" small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" @click="confermaEliminaRicambio" small>
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <!-- DIALOG ELIMINA FIGLIO  -->
    <v-dialog v-model="dialog_elimina_figlio" width="300">
      <v-card>
        <v-toolbar class="white--text" color="indigo" dense>
          <v-toolbar-title>Conferma Eliminazione</v-toolbar-title>
        </v-toolbar>
        <v-row class="mt-2 mb-2">
          <v-card-text>
            Vuoi elimirare la macchina:
            {{ macchina_tmp ? macchina_tmp.cod_macchina : "" }}
          </v-card-text>
        </v-row>

        <v-divider></v-divider>
        <v-btn class="mx-2 my-2" @click="chiudiEliminaFiglio" small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" @click="confermaEliminaFiglio" small>
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SchedaMantinance from "./SchedaMantinance";
import SchedaMantinanceStraordinaria from "./SchedaMantinanceStraordinaria";
import MacchinaProgamma from "../../../components/PRODUCTION/Impostazioni/PartProgramGet&Send";
import MacchinaProgammaConfig from "../../../components/PRODUCTION/Impostazioni/PartProgramIntializer";
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField.vue";
import moment from "moment";
import Industry4Vue from "./Industry4.vue";
export default {
  name: "UpdateVehicleDetail",
  components: {
    SchedaMantinance,
    SchedaMantinanceStraordinaria,
    Button,
    TextField,
    MacchinaProgamma,
    Industry4Vue,
    MacchinaProgammaConfig,
  },
  data() {
    return {
      // Variable for SnackBar
      isIndustria4: true,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      macchina_4: false,
      // Main Variable
      desArticoloSearch: "",
      articoloSearch: "",
      desSearch: "",
      macchinaSearch: "",
      ricambio_sel: null,
      ricambio_modifica_sel: null,
      macchina_sel_ricambo: null,
      quantita: null,
      dialog_elimina_figlio: false,
      dialog_elimina_ricambio: false,
      dialog_modifica_qta_ricambio: false,
      dialog_aggiungi_ricambio: false,
      dialog_conferma_sel_ricambio: false,
      conferma_finale_ricambio: false,

      id_macchina_sel: null,
      // macchina_attuale: null,
      macchina_sel: null,
      macchina_tmp: null,
      dialog_aggiungi_child: false,
      dialog_conferma_selezione: false,
      on: true,
      hideicons: true,
      tab: null,
      immagine_preview: "",
      allegato: "",
      selectedPdf: "",
      pdfdialog: false,
      dialogAggiungiAllegato: false,
      overlay: true,

      // ARRAY
      open: [0],
      headers_ricambi: [
        {
          text: "Modifica",
          align: "start",
          sortable: false,
          value: "edita",
        },
        { text: "Cod. Articolo", value: "cod_articolo" },
        { text: "Descrizione", value: "des_articolo" },
        { text: "Des. Aggiuntiva", value: "des_aggiuntiva" },
        { text: "Tipo Articolo", value: "tipo" },
        { text: "Quantità", value: "quantita" },
        { text: "Immagine", value: "immagine_preview" },
        { text: "Elimina", value: "elimina", sortable: false },
      ],
      headers_ricambi_articolo: [
        { text: "Cod. Articolo", value: "cod_articolo" },
        { text: "Descrizione", value: "des_articolo" },
        { text: "Des. Aggiuntiva", value: "des_aggiuntiva" },
        { text: "Tipo Articolo", value: "tipo" },
      ],
      headers_macchine: [
        { text: "Cod. Macchina", value: "cod_macchina" },
        { text: "Des. Macchina", value: "des_macchina" },
        { text: "Matricola", value: "matricola" },
        { text: "Marca", value: "marca" },
        { text: "Specifiche", value: "specifiche" },
      ],
      macchine_lista: [],
      items_ricambi_articoli: [],
      items_ricambi: [],
      des_funzione_list: [],
      des_controcosto_list: [],
      des_stato_list: [],
      des_reparto_list: [],
      arrayItemsTreeview: [],
      macchina_detail: { macchina: [{}], macchina_scheda_ordinaria: [{}] },
      id_macchina: null,
      validita: 0,
    };
  },
  watch: {
    tab: {
      immediate: true,
      handler() {
        if (this.tab == 3) {
          this.getMacchineRicambi();
        }
      },
    },
  },
  methods: {
    getimageMacchina() {
      let finale = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getmacchinapreviewimg",
          token: localStorage.getItem("user_token"),
          id_macchina: this.$route.params.orderId,
        },
      };
      this.$store.dispatch("api", finale).then((res) => {
        this.immagine_preview = res.data.result[0].macchina_img;
      });
    },
    aggiungiImg(messaggio) {
      /* Recupero l'immagine nel messaggio
       * che è un base64 non splittato. */
      let photo = messaggio.immagine_preview;

      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();

      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width >= img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.id_ricambi).style.width = width_nuova + "px";
          document.getElementById(messaggio.id_ricambi).style.height =
            height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.id_ricambi).style.width = width_nuova + "px";
          document.getElementById(messaggio.id_ricambi).style.height =
            height_nuova + "px";
        } else {
          document.getElementById(messaggio.id_ricambi).style.width = img.width + "px";
          document.getElementById(messaggio.id_ricambi).style.height = img.height + "px";
        }
      };

      img.src = photo;

      if (document.getElementById(messaggio.id_ricambi)) {
        this.$nextTick(() => {
          document.getElementById(messaggio.id_ricambi).style.backgroundImage =
            "url('" + img.src + "')";
        });
      }

      this.$nextTick(() => {
        document.getElementById(messaggio.id_ricambi).style.backgroundImage =
          "url('" + img.src + "')";
      });
    },
    // inserisciComponente(id_macchina_sel, macchina_tmp, macchina_attuale) {
    //   if (macchina_attuale.id === id_macchina_sel) {
    //     macchina_attuale.children.push(macchina_tmp);
    //   } else {
    //     macchina_attuale.children.forEach((figlio) => {
    //       this.inserisciComponente(id_macchina_sel, macchina_tmp, figlio);
    //     });
    //   }

    //   this.dialog_conferma_selezione = false;
    //   this.dialog_aggiungi_child = false;
    //   console.log(this.arrayItemsTreeview);
    // },
    confermaSelezioneRicambio() {
      if (this.quantita != null) {
        this.dialog_conferma_sel_ricambio = false;
        this.conferma_finale_ricambio = true;
      } else {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Non hai inserito una quantità";
        this.snackbar_text_color = "white";
      }
    },
    confermaFinaleRicambio() {
      let ricambio_tmp = {
        id_ricambi: -1,
        id_articolo: this.ricambio_sel.id_articolo,
        id_macchina: this.$route.params.orderId,
        quantita: this.quantita,
      };

      this.setMacchineRicambi(ricambio_tmp);
      this.conferma_finale_ricambio = false;
      this.dialog_aggiungi_ricambio = false;

      this.ricambio_sel = null;
      this.quantita = null;
    },
    closeSelezionaArticoloTree() {
      this.macchina_tmp = null;
      this.dialog_conferma_selezione = false;
    },
    selezionaMacchina() {
      this.macchina_tmp.validita = 1;
      // this.macchina_tmp.id_macchina_distinta = -1;
      this.setMacchineDistintaBase();

      this.dialog_conferma_selezione = false;
      this.dialog_aggiungi_child = false;
      this.macchina_tmp = null;

      // this.inserisciComponente(
      //   this.id_macchina_sel,
      //   this.macchina_tmp,
      //   this.macchina_attuale
      // );
    },
    clickEliminaRicambio(item) {
      this.dialog_elimina_ricambio = true;
      this.ricambio_modifica_sel = Object.assign({}, item);
    },
    clickEliminaFiglio(item) {
      this.macchina_tmp = Object.assign({}, item);
      this.dialog_elimina_figlio = true;
    },
    clickIndietroAggiungiRicambio() {
      this.dialog_aggiungi_ricambio = false;
      this.articoloSearch = "";
      this.desArticoloSearch = "";
      this.items_ricambi_articoli = [];
    },
    clickModificaRicambio(item) {
      this.ricambio_modifica_sel = Object.assign({}, item);
      this.dialog_modifica_qta_ricambio = true;
    },
    clickSelezionaRicambio(item) {
      let check_ricorre = false;

      this.items_ricambi.forEach((elem) => {
        if (elem.id_articolo === item.id_articolo) {
          check_ricorre = true;
        }
      });

      if (check_ricorre === true) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Ricambio già presente";
        this.snackbar_text_color = "white";
      } else {
        this.ricambio_sel = Object.assign({}, item);
        this.dialog_conferma_sel_ricambio = true;
      }
    },
    clickSelezionaArticolo(item) {
      let check_ricorre_macchina = false;

      this.arrayItemsTreeview[0].children.forEach((elem) => {
        if (elem.id_macchina === item.id_macchina) {
          check_ricorre_macchina = true;
        }
      });
      if (check_ricorre_macchina === true) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Macchina già presente";
        this.snackbar_text_color = "white";
      } else {
        this.macchina_tmp = Object.assign({}, item);
        this.dialog_conferma_selezione = true;
      }
    },
    clickAggiungiChild(item) {
      this.macchina_sel = Object.assign({}, item);
      this.id_macchina_sel = item.id;
      // this.macchina_attuale = this.arrayItemsTreeview[0];

      this.dialog_aggiungi_child = true;
    },
    clickAggiungiRicambio() {
      this.dialog_aggiungi_ricambio = true;
    },
    chiudiConfermaRicambio() {
      this.conferma_finale_ricambio = false;
      this.ricambio_sel = null;
      this.quantita = null;
    },
    chiudiInserisciComponente() {
      this.macchinaSearch = "";
      this.desSearch = "";
      this.macchine_lista = [];
      this.dialog_aggiungi_child = false;
    },
    chiudiModifica() {
      this.ricambio_modifica_sel = null;
      this.quantita = null;
      this.dialog_modifica_qta_ricambio = false;
    },
    chiudiElimina() {
      this.ricambio_modifica_sel = null;
      this.dialog_elimina_ricambio = false;
    },
    chiudiEliminaFiglio() {
      this.macchina_tmp = null;
      this.dialog_elimina_figlio = false;
    },
    chiudiSelezionaQuantita() {
      this.ricambio_sel = null;
      this.quantita = null;
      this.dialog_conferma_sel_ricambio = false;
    },
    confermaModificaRicambio() {
      if (this.quantita != null) {
        this.dialog_modifica_qta_ricambio = false;
        this.ricambio_modifica_sel.quantita = this.quantita;
        this.setMacchineRicambi(this.ricambio_modifica_sel);
        this.ricambio_modifica_sel = null;
      } else {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Non hai inserito una quantità";
        this.snackbar_text_color = "white";
      }
    },
    confermaEliminaRicambio() {
      this.dialog_elimina_ricambio = false;
      this.ricambio_modifica_sel.validita = 0;
      this.setMacchineRicambi(this.ricambio_modifica_sel);

      this.ricambio_modifica_sel = null;
    },
    confermaEliminaFiglio() {
      this.dialog_elimina_figlio = false;
      this.macchina_tmp.validita = 0;
      this.setMacchineDistintaBase();

      this.macchina_tmp = null;
      this.macchine_lista = [];
    },
    clickLenteRicambio() {
      if (this.articoloSearch == "*" || this.desArticoloSearch == "*") {
        this.articoloSearch = "";
        this.desArticoloSearch = "";
        this.getRicambi();
      } else if (this.articoloSearch == "" && this.desArticoloSearch == "") {
        return;
      } else {
        this.getRicambi();
      }
    },
    clickLenteMacchina() {
      if (this.macchinaSearch == "*" || this.desSearch == "*") {
        this.macchinaSearch = "";
        this.desSearch = "";
        this.getMacchinaList();
      } else if (this.macchinaSearch == "" && this.desSearch == "") {
        return;
      } else {
        this.getMacchinaList();
      }
    },
    getMacchinaList() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlistamacchine",
          token: localStorage.getItem("user_token"),
          cod_art: this.macchinaSearch,
          descrizione: this.desSearch,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        let arr = [];

        res.data.forEach((elem) => {
          if (elem.id_macchina != this.$route.params.orderId) {
            arr.push(elem);
          }
        });

        this.macchine_lista = arr;
      });
    },
    getSchedaMacchina() {
      this.id_macchina = this.$route.params.orderId;

      // TO populate the list of select fields
      let req_data = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MACCHINA_STATO','MACCHINA_REPARTO','MACCHINA_FUNZIONE','MACCHINA_CENTRO_COSTO'",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", req_data).then((res) => {
        this.des_controcosto_list = res.data.MACCHINA_CENTRO_COSTO;
        this.des_funzione_list = res.data.MACCHINA_FUNZIONE;
        this.des_reparto_list = res.data.MACCHINA_REPARTO;
        this.des_stato_list = res.data.MACCHINA_STATO;
      });
      //IN Order to get Scheda Detail of a macchina
      let fetchMacchina = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getschedamacchina",
          token: localStorage.getItem("user_token"),
          idMacchina: this.$route.params.orderId,
        },
      };
      // Attivo l'overlay di caricamento
      this.overlay = true;
      this.$store.dispatch("api", fetchMacchina).then((res) => {
        this.overlay = false;

        // Aggiusto la data di acuisto della macchina
        let val = moment(res.data.macchina[0].data_acquisto, [
          "YYYY-MM-DD hh:mm:ss",
        ]).format("DD/MM/YYYY");
        res.data.macchina[0].data_acquisto = val;

        // Registro i dati della response modificata.
        this.macchina_detail = JSON.parse(JSON.stringify(res.data));
      });
    },
    // To Go back macchona list
    goMacchina() {
      this.$router.push("/home/production/macchina");
    },
    // to open Modal for PDF
    openPdfAppenaCaricato(item) {
      if (item.startsWith("data:application/pdf")) {
        this.selectedPdf = item;
        this.pdfdialog = true;
      }
    },
    // Function to handle pasted url of image
    onPaste(e) {
      if (e.clipboardData != false) {
        var items = e.clipboardData.items;
        if (items != undefined) {
          for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();
            console.log(blob);
          }
        }
      }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        that.immagine_preview = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    hasExtension(file, exts) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name
      );
    },
    hideIcons() {
      this.hideicons = false;
      this.on = false;
      this.open = [];
    },
    // Function to select an image and change it to base64
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        this.hasExtension(files[0], [".jpeg", ".gif", ".png", ".jpg", ".pdf", ".PNG"])
      ) {
        this.allegato = files[0];
        var reader = new FileReader();
        reader.readAsDataURL(this.allegato);
        let that = this;
        reader.onload = function () {
          var image = new Image();
          image.src = event.target.result;
          image.onload = function () {
            that.resizeImage(image, "immagine");
          };
          that.immagine_preview = reader.result;
          localStorage.setItem("MacchinaImage", that.immagine_preview);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        this.dialogAggiungiAllegato = false;
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Formato non valido";
        this.snackbar_text_color = "white";
      }
      this.dialogAggiungiAllegato = false;
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      var items = pasteEvent.clipboardData.items;
      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();
        // Create an abstract canvas and get context
        var mycanvas = document.createElement("canvas");
        var ctx = mycanvas.getContext("2d");
        // Create an image
        var img = new Image();
        // Once the image loads, render the img on the canvas
        img.onload = function () {
          // Update dimensions of the canvas with the dimensions of the image
          mycanvas.width = this.width;
          mycanvas.height = this.height;
          // Draw the image
          ctx.drawImage(img, 0, 0);
          // Execute callback with the base64 URI of the image
          if (typeof callback == "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
          }
        };
        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;
        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
      }
      this.dialogAggiungiAllegato = false;
    },
    // In order to resize the image
    resizeImage(img, id) {
      if (img.width >= img.height && img.width > 100) {
        let width_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let height_nuova = (width_nuova * img.height) / img.width;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else if (img.height > img.width && img.height > 100) {
        let height_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let width_nuova = (height_nuova * img.width) / img.height;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else {
        document.getElementById(id).style.width = img.width + "px";
        document.getElementById(id).style.height = img.height + "px";
      }
    },
    // PDF MOdal
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },

    // TO Save or Update the Macchina Details
    salvaMacchina() {
      this.overlay = true;
      let data = this.macchina_detail.macchina[0];
      data.macchina_img = this.immagine_preview;
      let dataarray = [];
      dataarray.push(data);
      let finale = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "updatemacchina",
          token: localStorage.getItem("user_token"),
          tabella: dataarray,
        },
      };
      this.$store.dispatch("api", finale).then(() => {
        this.overlay = false;
        this.snackbar = true;
        this.snackbar_icon = "mdi-check";
        this.snackbar_background = "success";
        this.snackbar_text = "Modifica effettuata con successo!";
        this.snackbar_text_color = "white";
      });
    },
    showIcons() {
      this.hideicons = true;
      this.open = [];
      this.on = true;
    },
    // CHIAMATE BE
    getMacchineDistintaBase() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getmacchinedistintabase",
          token: localStorage.getItem("user_token"),
          id_macchina: this.$route.params.orderId,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.arrayItemsTreeview = res.data;

        // this.arrayItemsTreeview[0].children.forEach((elem) => {
        //   elem.btn = true;
        // });
      });
    },
    getMacchineRicambi() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getmacchinericambi",
          token: localStorage.getItem("user_token"),
          id_macchina: this.$route.params.orderId,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.items_ricambi = res.data.ricambi;

        this.items_ricambi.forEach((item) => {
          if (item.immagine_preview) {
            this.aggiungiImg(item);
          }
        });
      });
    },
    getRicambi() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getfamigliaricambi",
          token: localStorage.getItem("user_token"),
          cod_art: this.articoloSearch,
          descrizione: this.desArticoloSearch,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.items_ricambi_articoli = res.data.ricambi;
      });
    },
    setMacchineRicambi(arg) {
      let arr = [];
      arr.push(arg);

      let richiesta = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setmacchinericambi",
          token: localStorage.user_token,
          ricambi: arr,
        },
      };
      this.$store.dispatch("api", richiesta).then((res) => {
        console.log(res);
        this.getMacchineRicambi();
        this.ricambio_sel = null;
        this.quantita = null;
      });
    },
    setMacchineDistintaBase() {
      let richiesta = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setmacchinedistintabase",
          token: localStorage.user_token,
          distintabase: [
            {
              id_macchina_distinta: this.macchina_tmp.id ? this.macchina_tmp.id : -1,
              id_macchina: this.macchina_tmp.id_macchina,
              id_impianto: this.$route.params.orderId,
              validita: this.macchina_tmp.validita,
            },
          ],
        },
      };
      this.$store.dispatch("api", richiesta).then((res) => {
        console.log(res);
        this.getMacchineDistintaBase();
      });
    },
  },
  mounted() {
    /* When this module is dynamically loaded into an external module,
     * its this.$route.params.orderId might not be assigned a value.
     * In such case, I emit a signal to make tha caller aware of the readyness
     * of the UpdateVehicleDetail component and prevent BE initial API
     * calls; the parent component will make so that initialization
     * is performed in some other way. */
    this.$emit("whoIsMyFather");

    this.$nextTick(() => {
      // If the router prop has a value, BE call are performed.
      this.getSchedaMacchina();
      this.getMacchineDistintaBase();
      this.getimageMacchina();
    });
  },
};
</script>

<style>
.sfondoStandard {
  background: linear-gradient(90deg, rgba(63, 81, 181, 1) 0%, rgba(63, 81, 181, 1) 100%);
  color: white !important;
}
</style>
