<template>
  <div class="">
    <v-dialog
      content-class="vehDialog"
      persistent
      v-model="dialog"
      width="1100"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" small>
          <v-icon class="modalbutton" color="#1a80b6" dark v-on="on">
            mdi-magnify
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text">
            Macchina {{ this.item.id_macchina }}:
            {{ this.orderDetail.macchina[0].des_macchina }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class=""
                @click="
                  dialog = false;
                  registerManutenzione();
                "
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon dark tile color="success">mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" small @click="dialog = false">
                <v-icon depressed class="" color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>
        <v-form>
          <v-container>
            <v-row>
              <v-col class="py-0" cols="4" md="1">
                <TextField
                  idForm="MACCHINA_SCHEDA"
                  idOggetto="SCHEDA_DETT_NUM_OPERAZIONE"
                  label_input="Num Operazione"
                  palceholder_input="Num Operazione"
                  :vmodel="this.item.num_operazione"
                  :readonly="true"
                />
              </v-col>
              <v-col class="py-0" cols="4" md="2">
                <TextField
                  idForm="MACCHINA_SCHEDA"
                  idOggetto="SCHEDA_DETT_TIPO_OPERAZIONE"
                  label_input="Tipo Operazione"
                  palceholder_input="Tipo Operazione"
                  :vmodel="this.item.des_tipo_operazione"
                  :readonly="true"
                />
              </v-col>
              <v-col class="py-0" cols="4" md="2">
                <TextField
                  idForm="MACCHINA_SCHEDA"
                  idOggetto="SCHEDA_DETT_DUM"
                  label_input="D.U.M"
                  palceholder_input="D.U.M"
                  :vmodel="dum"
                  :readonly="true"
                />
              </v-col>
              <v-col class="py-0" cols="4" md="2">
                <TextField
                  idForm="MACCHINA_SCHEDA"
                  idOggetto="SCHEDA_DETT_METOCO_CACLCOLO"
                  label_input="Metodo calcolo"
                  palceholder_input="Metodo calcolo"
                  :vmodel="this.item.des_metodo_calcolo"
                  :readonly="true"
                />
              </v-col>
              <v-col class="py-0" cols="4" md="1">
                <TextField
                  idForm="MACCHINA_SCHEDA"
                  idOggetto="SCHEDA_DETT_DES_CACLCOLO"
                  label_input="Calcolo"
                  palceholder_input="Calcolo"
                  :vmodel="this.item.des_calcolo"
                  :readonly="true"
                />
              </v-col>
              <v-col class="py-0" cols="4" md="1">
                <TextField
                  idForm="MACCHINA_SCHEDA"
                  idOggetto="SCHEDA_DETT_ORE_GIORNI"
                  label_input="Ore Giorni"
                  palceholder_input="Ore Giorni"
                  :vmodel="this.item.ore_giorni"
                  :readonly="true"
                />
              </v-col>
              <v-col class="py-0" cols="5" md="2">
                <v-switch
                  v-model="this.item.scadenza"
                  label="Scadenza"
                  :false-value="0"
                  :true-value="1"
                  readonly
                ></v-switch>
              </v-col>
              <v-col class="py-0" cols="4" md="9">
                <TextField
                  idForm="MACCHINA_SCHEDA"
                  idOggetto="SCHEDA_DETT_DES"
                  label_input="Descrizione Scheda"
                  palceholder_input="Descrizione Scheda"
                  :vmodel="this.item.des_scheda"
                  :readonly="true"
                />
              </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-card>
              <v-toolbar dense class="indigo darken-1 headline" primary-title>
                <v-toolbar-title class="white--text">
                  Dati Manutenzione Eseguita
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col class="py-0" cols="6" md="2">
                      <v-select
                        v-model="this.item.des_responsabile"
                        :items="responsabile_list"
                        item-value="des"
                        item-text="des"
                        label="Responsabile"
                        :placeholder="this.item.des_responsabile"
                        required
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col class="py-0" cols="6" sm="6" md="2">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="date_manutenzione_format"
                            label="Data Manutenzione"
                            prepend-icon="event"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="data_ultima_manutenzione"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="d-flex py-0" cols="4" md="2">
                      <v-select
                        v-model="esecutore"
                        :items="responsabile_list"
                        item-text="des"
                        label="Esecutore"
                        required
                      ></v-select>
                    </v-col>
                    <v-col class="py-0" cols="4" md="2">
                      <TextField
                        idForm="MACCHINA_SCHEDA"
                        idOggetto="SCHEDA_DETT_ORE_INTERVENTO"
                        label_input="Ore Intervento"
                        palceholder_input="Ore Intervento"
                        :vmodel="ore_intervento"
                        :min_input="0"
                        type_input="number"
                        v-on:changeText="(val) => (this.ore_intervento = val)"
                      />
                    </v-col>
                    <v-col class="py-0" cols="4" md="2">
                      <v-select
                        v-model="esito"
                        :items="esito_list"
                        item-text="des"
                        item-value="id_tabella"
                        label="Esito"
                        required
                      ></v-select>
                    </v-col>
                    <v-col class="py-0 shrink" cols="12" md="12">
                      <v-textarea
                        type="text"
                        height:1
                        v-model="esito_manutenzione"
                        label="Esito Manutenzione"
                        required
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-container>
        </v-form>
        <v-card-actions class="mt-n10">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField.vue";
export default {
  components: {
    TextField,
  },
  props: ["orderDetail", "item"],
  data() {
    return {
      // All the Variable needed for this components
      dum: moment(this.item.data_ultima_manutenzione).format("DD/MM/YYYY"),
      des_macchina: this.orderDetail.macchina[0].des_macchina,
      num_operazione: this.item.num_operazione,
      id_tipo_operazione: this.item.id_tipo_operazione,
      id_metodo_calcolo: this.item.id_metodo_calcolo,
      id_utente: this.item.id_utente,
      id_calcolo: this.item.id_calcolo,
      scadenza: this.item.scadenza,
      ore_giorni: this.item.ore_giorni,
      id_reparto: this.item.id_reparto,
      id_macchina_scheda_ordinaria: this.orderDetail
        .id_macchina_scheda_ordinaria,
      id_responsabile: this.item.id_responsabile,
      des_scheda: this.item.des_scheda,
      dialog: false,
      data_ultima_manutenzione: this.item.data_ultima_manutenzione,
      menu2: false,
      esecutore: "",
      responsabile_list: [],
      esito_list: [],
      date_manutenzione: new Date().toISOString().substr(0, 10),
      ore_intervento: 0,
      esito_manutenzione: "",
      esito: this.item.id_esito,
    };
  },
  methods: {
    // TO clear the form fields values
    clearFields() {
      (this.esecutore = ""),
        (this.ore_intervento = ""),
        (this.esito_manutenzione = ""),
        (this.date_manutenzione = "");
    },
    // In order to add extra details to any Scheda
    registerManutenzione() {
      console.log(JSON.stringify(this.item));
      // console.log(JSON.stringify(this.item));
      let dataarray = [];
      let formdata = {
        id_macchina_manutenzione_ordinaria: this.item
          .id_macchina_manutenzione_ordinaria,
        id_macchina_scheda_ordinaria: this.item.id_macchina_scheda_ordinaria,
        id_macchina: this.item.id_macchina,
        num_operazione: this.item.num_operazione,
        id_tipo_operazione: this.id_tipo_operazione,
        scadenza: this.scadenza,
        data_registrazione: this.date_manutenzione,
        ore_giorni: this.ore_giorni,
        id_calcolo: this.id_calcolo,
        id_metodo_calcolo: this.id_metodo_calcolo,
        validita: this.item.validita,
        des_macchina: this.orderDetail.macchina[0].des_macchina,
        ore_intervento: this.ore_intervento,
        esito: this.esito,
        note: this.esito_manutenzione,
        data_ultima_manutenzione: this.item.data_ultima_manutenzione.toString().substr(0,10),
        date_ins: this.item.date_ins,
        date_mod: this.item.date_mod,
        id_esecutore: this.esecutore,
        id_utente: this.item.id_utente,
        id_responsabile: this.item.id_responsabile,
      };
      this.responsabile_list.map((el) => {
        if (el.des === formdata.id_esecutore)
          formdata.id_esecutore = el.id_tabella;
        return formdata;
      });
      dataarray.push(formdata);
      let finale = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setmanutenzione",
          token: localStorage.getItem("user_token"),
          id_utente: this.item.id_utente,
          tabella: dataarray,
        },
      };
      this.$store.dispatch("api", finale).then(res=>{
        if(res.status == 200){
          this.$emit("aggiornaScheda");
        }
      });
      this.clearFields();
    },
  },
  computed: {
    date_manutenzione_format() {
      return this.data_ultima_manutenzione
        ? moment(this.data_ultima_manutenzione).format("DD/MM/YYYY")
        : "";
    },
  },
  mounted() {
    //   In order to get list of select fields to fill the form data
    let req_data = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo:
          "'MANUTENZIONE_TIPO_OPERAZIONE','MANUTENZIONE_METODO_CALCOLO','MANUTENZIONE_CALCOLO','MANUTENZIONE_RESPONSABILE','MAN_ESITO'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", req_data).then((res) => {
      this.responsabile_list = res.data.MANUTENZIONE_RESPONSABILE;
      this.esito_list = res.data.MAN_ESITO;
    });
  },
};
</script>
<style scoped>
.vehDialog {
  overflow-x: hidden;
}
.modalbutton {
  width: 10px;
}
.addButton {
  margin-left: -0.1rem !important;
  color: white !important;
}
</style>
