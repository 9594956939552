<template>
  <div>
    <!-- Snackbar -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-container fluid class="my-0 py-0">
      <v-card :elevation="8">
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">
            Scheda Manutenzione Ordinaria
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <AddSchedaModal
            style="margin-left: 1rem"
            :key="1"
            :orderDetail="macchinaDetail"
            @aggiornaScheda="aggiornaScheda()"
          />
          <v-spacer></v-spacer>

          <v-text-field
            style="background: white"
            v-model="search"
            append-icon="mdi-magnify"
            label="Cerca"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>

        <v-card-text>
          <v-data-table
            :items-per-page="5"
            :loading="false"
            :headers="headers"
            :items="items.filter((elem) => elem.validita)"
            :search="search"
            class="mt-3 tablestyle"
            :dense="true"
          >
            <v-progress-linear
              v-show="progressBar"
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item="props">
              <tr>
                <td>
                  <UpdateSchedaModal
                    :item="props.item"
                    @aggiornaScheda="aggiornaScheda"
                    :key="item.id_macchina_scheda_ordinaria"
                    :updateSchedaModal="updateSchedaModal"
                  />
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-col v-on="on" v-bind="attrs">
                        <Modal
                          v-on="on"
                          v-bind="attrs"
                          @aggiornaScheda="aggiornaScheda"
                          :orderDetail="macchinaDetail"
                          :item="props.item"
                          :key="props.item.id_macchina_scheda_ordinaria"
                          :repartoDetail="reparto"
                          :responsabiles="responsableList"
                          :responsabilelistId="responsabilelistId"
                        />
                      </v-col>
                    </template>
                    <span>Scheda Dettaglio</span>
                  </v-tooltip>
                </td>
                <td>{{ props.item.num_operazione }}</td>
                <td>
                  {{ props.item.des_tipo_operazione }}
                </td>
                <td>{{ props.item.ore_giorni }}</td>
                <td>{{ props.item.des_calcolo }}</td>
                <td>{{ props.item.des_metodo_calcolo }}</td>
                <td>{{ props.item.des_responsabile }}</td>
                <td>{{ props.item.des_scheda }}</td>
                <td>
                  {{ props.item.data_ultima_manutenzione | formatDate }}
                </td>
                <td>
                  {{ props.item.data_prossima_manutenzione | formatDate }}
                </td>
                <td>
                  {{ props.item.des_esito }}
                </td>
                <td>
                  <Button
                    colore_icona="error"
                    idForm="MACCHINA_SCHEDA"
                    idOggetto="ELIMINA_MACCHINA_SCHEDA"
                    mdi_icona="mdi-window-close"
                    stile_icona="float: left"
                    testo_tooltip="Rimuovere Scheda"
                    v-on:buttonClick="deleteitem(props.item)"
                  >
                  </Button>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import AddSchedaModal from "./AddSchedaModal";
import UpdateSchedaModal from "./UpdateSchedaModal";
import Modal from "./SchedaDetailModal";
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
export default {
  name: "VehicleDetail",
  //because u pass schedas and no one refresh this i think ok i have to do the same for update modal?
  props: [
    "schedas",
    "reparto",
    "responsableList",
    "responsabilelistId",
    "macchinaDetail",
    "CalcoloList",
    "metodoCacoloList",
    "tipoOperazione",
  ],
  // SubComponents of Scheda
  components: {
    Modal,
    AddSchedaModal,
    UpdateSchedaModal,
    Button,
  },
  data() {
    return {
      // Variables for snackbar
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      // Data Variables that are required
      item: {},
      updateSchedaModal: false,
      progressBar: true,
      metodo_calcolo_list: [],
      tipo_operazione_list: [],
      calcoloList: [],
      responsabile_list_Id: [],
      items: new Array(),
      Items: [],
      macchina_detail: new Array(),
      // Header of scheda table
      headers: [
        {
          text: "Modifica",
          value: "actions",
          align: "center",
          sortable: false,
        },
        {
          text: "Dettagli",
          value: "actions",
          align: "center",
          sortable: false,
        },
        {
          text: "Num Operazione",
          align: "start",
          value: "num_operazione",
          sortable: false,
        },
        {
          text: "Tipo Operazione",
          value: "des_tipo_operazione",
          align: "start",
          sortable: false,
        },
        {
          text: "Ore Giorni",
          value: "ore_giorni",
          align: "start",
          sortable: false,
        },
        {
          text: "Calcolo",
          value: "des_calcolo",
          align: "start",
          sortable: false,
        },
        {
          text: "Metodo Calcolo",
          value: "des_metodo_calcolo",
          align: "start",
          sortable: false,
        },
        {
          text: "Responsabile",
          value: "des_responsabile",
          align: "start",
          sortable: false,
        },
        {
          text: "Des Scheda",
          value: "des_scheda",
          align: "start",
          sortable: false,
        },
        {
          text: "Data Ultima Manutenzione",
          value: "data_ultima_manutenzione",
          align: "start",
          sortable: false,
        },
        {
          text: "Data Prossima Manutenzione",
          value: "data_prossima_manutenzione",
          align: "start",
          sortable: false,
        },
        {
          text: "Stato",
          value: "des_esito",
          align: "center",
          sortable: false,
        },
        {
          text: "Elimina",
          align: "Center",
          sortable: false,
        },
      ],
      search: "",
    };
  },
  mounted() {
    //To move table columns
    console.log("ciao");
    console.log(JSON.stringify(this.schedas));
    if (this.schedas.length > 0) {
      this.progressBar = false;
    }
  },
  watch: {
    macchinaDetail() {
      const m = this.schedas;
      this[m] = !this[m];
      return;
    },
    macchina_detail() {
      const m = this.macchinaDetail;
      this[m] = !this[m];
    },
    schedas: {
      immediate: true,
      handler() {
        this.items = Array.from(this.schedas);
        this.macchina_detail = Array.from(this.schedas);
      },
    },
  },
  methods: {
    getitem(val) {
      this.item = val;
      this.updateSchedaModal = true;
    },
    // To Delete a single Scheda
    deleteitem(item) {
      item.validita = 0;
      let dataarray = [item];
      let del = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "updatescheda",
          token: localStorage.getItem("user_token"),
          tabella: dataarray,
        },
      };
      this.$store.dispatch("api", del).then(() => {
        this.snackbar = true;
        this.snackbar_icon = "mdi-check";
        this.snackbar_background = "success";
        this.snackbar_text = "Modifica effettuata con successo!";
        this.snackbar_text_color = "white";
      });
      let i = this.items.findIndex(
        (it) =>
          it.id_macchina_scheda_ordinaria === item.id_macchina_scheda_ordinaria
      );
      this.items.splice(i, 1);
    },

    aggiornaScheda() {
      this.snackbar = true;
      this.snackbar_icon = "mdi-check";
      this.snackbar_background = "success";
      this.snackbar_text = "Modifica effettuata con successo!";
      this.snackbar_text_color = "white";
      //something like this ok got it thanks when i finish i will send you the dist ok
      this.$emit("updateScheda");
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.vehDialog {
  overflow-x: hidden !important;
}
</style>
