<template>
  <v-card>
    <v-dialog persistent width="400" v-model="alert.mostra">
      <v-card>
        <v-container>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            v-show="this.alert.loader"
          ></v-progress-circular>
          <v-row v-if="!alert.loader" align="center" justify="center">
            <v-col cols="1" align="center">
              <v-icon large>{{ alert.icona }}</v-icon>
            </v-col>
            <v-col cols="11">
              <h3 style="word-break:keep-all;text-align:left;padding:0 20px">
                {{ alert.messaggio }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions v-show="!this.alert.loader">
          <v-spacer />
          <v-btn text color="primary" @click="alert.mostra = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="ml-2  mt-1" md="4" sm="4" lg="4">
        <v-select
          outlined
          v-model="id_gateway"
          :items="gateway_lista"
          label="Gateway"
          item-text="device_name"
          item-value="device_id"
          persistent-hint
        ></v-select>
      </v-col>
    </v-row>

    <v-toolbar flat dense class="indigo darken-1 headline " primary-title>
      <v-toolbar-title class="white--text">Interfaces</v-toolbar-title>
      <v-spacer></v-spacer>
      <Button
        colore_icona="primary"
        mdi_icona="mdi-plus"
        testo_tooltip="Aggiornare"
        v-on:buttonClick="CreateNewInterface"
      />
      <Button
        classe_bottone="ml-4"
        colore_icona="success"
        mdi_icona="mdi-floppy"
        testo_tooltip="Salva"
        v-on:buttonClick="saveInterface"
      />

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-expansion-panels
      @change="updateTemplate"
      v-if="configuration != null"
      focusable
    >
      <v-expansion-panel v-for="(interf, i) in interfaces" :key="i">
        <v-dialog
          hide-overflow
          v-model="variable_dialog"
          width="800"
          :retain-focus="false"
        >
          <v-card>
            <v-toolbar dense class="indigo darken-1 headline" primary-title>
              <v-toolbar-title class="white--text">Variable</v-toolbar-title>
              <v-spacer></v-spacer>
              <Button
                stile_bottone="float:center"
                colore_icona="success"
                mdi_icona="mdi-floppy"
                testo_tooltip="Salva"
                v-on:buttonClick="saveVariable"
              />

              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="variable_dialog = false"
                    small
                  >
                    <v-icon depressed class="" color="error"
                      >mdi-window-close</v-icon
                    >
                  </v-btn>
                </template>
                <span>Cancellare</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-row v-if="interf.transport_layer != null">
                <v-col
                  v-for="(i_field, j) in variable_template"
                  :key="j"
                  md="3"
                  sm="3"
                  lg="3"
                >
                  <DynamicInput
                    v-model="variable_dialog_template[i_field.field]"
                    :type="i_field.type"
                    :items="i_field.values"
                    :label="i_field.name"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          hide-overflow
          v-model="register_dialog"
          width="800"
          :retain-focus="false"
        >
          <v-card>
            <v-toolbar dense class="indigo darken-1 headline" primary-title>
              <v-toolbar-title class="white--text">Register</v-toolbar-title>
              <v-spacer></v-spacer>
              <Button
                stile_bottone="float:center"
                colore_icona="success"
                mdi_icona="mdi-floppy"
                testo_tooltip="Salva"
                v-on:buttonClick="saveRegister"
              />

              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="register_dialog = false"
                    small
                  >
                    <v-icon depressed class="" color="error"
                      >mdi-window-close</v-icon
                    >
                  </v-btn>
                </template>
                <span>Cancellare</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-row v-if="interf.transport_layer != null">
                <v-col
                  v-for="(i_field, j) in register_template"
                  :key="j"
                  md="3"
                  sm="3"
                  lg="3"
                >
                  <DynamicInput
                    v-model="register_dialog_template[i_field.field]"
                    :type="i_field.type"
                    :items="i_field.values"
                    :label="i_field.name"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          hide-overflow
          v-model="alarm_dialog"
          width="800"
          :retain-focus="false"
        >
          <v-card>
            <v-toolbar dense class="indigo darken-1 headline" primary-title>
              <v-toolbar-title class="white--text">Alarm</v-toolbar-title>
              <v-spacer></v-spacer>
              <Button
                stile_bottone="float:center"
                colore_icona="success"
                mdi_icona="mdi-floppy"
                testo_tooltip="Salva"
                v-on:buttonClick="saveAlarm"
              />

              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="alarm_dialog = false"
                    small
                  >
                    <v-icon depressed class="" color="error"
                      >mdi-window-close</v-icon
                    >
                  </v-btn>
                </template>
                <span>Cancellare</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-row v-if="interf.transport_layer != null">
                <v-col
                  v-for="(i_field, j) in alarm_template"
                  :key="j"
                  md="3"
                  sm="3"
                  lg="3"
                >
                  <DynamicInput
                    v-model="alarm_dialog_template[i_field.field]"
                    :type="i_field.type"
                    :items="i_field.values"
                    :label="i_field.name"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          hide-overflow
          v-model="upload_dialog"
          width="800"
          :retain-focus="false"
        >
          <v-card>
            <v-toolbar dense class="indigo darken-1 headline" primary-title>
              <v-toolbar-title class="white--text">Upload</v-toolbar-title>
              <v-spacer></v-spacer>
              <Button
                stile_bottone="float:center"
                colore_icona="success"
                mdi_icona="mdi-floppy"
                testo_tooltip="Salva"
                v-on:buttonClick="saveUpload(interf)"
              />

              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="upload_dialog = false"
                    small
                  >
                    <v-icon depressed class="" color="error"
                      >mdi-window-close</v-icon
                    >
                  </v-btn>
                </template>
                <span>Cancellare</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-row v-if="interf.transport_layer != null">
                <v-col md="4" sm="4" lg="4">
                  <v-text-field
                    v-model="upload_dialog_template.upload_name"
                    placeholder="Upload Name"
                    label="Upload Name"
                  >
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="4" lg="4">
                  <v-text-field
                    v-model="upload_dialog_template.template"
                    placeholder="Template"
                    label="Template"
                  >
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="4" lg="4">
                  <v-select
                    v-model="upload_dialog_template.variables"
                    :items="filterVariables(interf)"
                    :menu-props="{ maxHeight: '400' }"
                    label="Variables"
                    multiple
                    hint="Pick your Variables"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-expansion-panel-header disable-icon-rotate
          >{{ interf.interface_name }}
          <template v-slot:actions>
            <v-icon class="icon">$expand</v-icon>
            <v-icon
              @click="deleteInterface(interf)"
              color="error"
              class="icon ml-5"
              >mdi-window-close</v-icon
            >
          </template>

          <v-spacer></v-spacer>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col md="3" sm="3" lg="3">
              <v-select
                v-bind:value="getTransport(interf)"
                :items="configuration.transport_layers"
                item-text="name"
                label="Transport Layer"
                return-object
                @change="changeTransport($event, interf)"
              />
            </v-col>
            <v-col md="3" sm="3" lg="3">
              <v-select
                @change="changeDataLayer($event, interf)"
                v-bind:value="getData(interf)"
                :items="interf._supported_data_layers"
                item-text="name"
                label="Data Layer"
                return-object
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(i_field, j) in configuration.interface_fields"
              :key="j"
              md="3"
              sm="3"
              lg="3"
            >
              <DynamicInput
                v-model="interf[i_field.field]"
                :type="i_field.type"
                :items="i_field.values"
                :label="i_field.name"
              />
            </v-col>
          </v-row>
          <v-row v-if="interf.transport_layer != null">
            <v-col
              v-for="(i_field, j) in configuration.transport_layers[
                interf.transport_layer
              ].interface_fields"
              :key="j"
              md="3"
              sm="3"
              lg="3"
            >
              <DynamicInput
                v-model="interf[i_field.field]"
                :type="i_field.type"
                :items="i_field.values"
                :label="i_field.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="12" lg="6">
              <v-data-table
                :items="interf.registers"
                :headers="getHeader(register_template)"
                :single-expand="true"
                :expanded.sync="expanded.register"
                show-expand
                item-key="_id"
                class="elevation-1"
                title="Variables"
                @item-expanded="setCurrentItem($event, 'register')"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-data-table
                      :items="item.variables"
                      :headers="getHeader(variable_template)"
                      :single-expand="true"
                      :expanded.sync="expanded.variable"
                      item-key="_id"
                      show-expand
                      @item-expanded="setCurrentItem($event, 'variable')"
                    >
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-data-table
                            :items="item.alarms"
                            :headers="getHeader(alarm_template)"
                            :single-expand="true"
                            :expanded.sync="expanded.alarm"
                            item-key="_id"
                            show-expand
                            @item-expanded="setCurrentItem($event, 'alarm')"
                          >
                            <template v-slot:top>
                              <v-toolbar
                                flat
                                dense
                                class="indigo darken-1 headline "
                                primary-title
                              >
                                <v-toolbar-title class="white--text"
                                  >Alarms</v-toolbar-title
                                >
                                <v-spacer></v-spacer>
                                <Button
                                  colore_icona="primary"
                                  mdi_icona="mdi-plus"
                                  testo_tooltip="Aggiornare"
                                  v-on:buttonClick="createAlarm(item)"
                                />
                                <v-spacer></v-spacer>
                              </v-toolbar>
                            </template>
                            <template v-slot:[`item._edit`]="{ item }">
                              <Button
                                colore_icona="primary"
                                mdi_icona="mdi-pencil"
                                testo_tooltip="Aggiornare"
                                v-on:buttonClick="openAlarm(item)"
                              />
                            </template>

                            <template v-slot:[`item._delete`]="item">
                              <Button
                                colore_icona="error"
                                mdi_icona="mdi-window-close"
                                testo_tooltip="Cancellare"
                                v-on:buttonClick="deleteAlarm(item)"
                              />
                            </template>
                          </v-data-table>
                        </td>
                      </template>
                      <template v-slot:top>
                        <v-toolbar
                          flat
                          dense
                          class="indigo darken-1 headline "
                          primary-title
                        >
                          <v-toolbar-title class="white--text"
                            >Variables</v-toolbar-title
                          >
                          <v-spacer></v-spacer>
                          <Button
                            colore_icona="primary"
                            mdi_icona="mdi-plus"
                            testo_tooltip="Aggiornare"
                            v-on:buttonClick="createVariable(item)"
                          />
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item._edit`]="{ item }">
                        <Button
                          colore_icona="primary"
                          mdi_icona="mdi-pencil"
                          testo_tooltip="Aggiornare"
                          v-on:buttonClick="openVariable(item)"
                        />
                      </template>

                      <template v-slot:[`item._delete`]="item">
                        <Button
                          colore_icona="error"
                          mdi_icona="mdi-window-close"
                          testo_tooltip="Cancellare"
                          v-on:buttonClick="deleteVariable(item)"
                        />
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template v-slot:top>
                  <v-toolbar
                    flat
                    dense
                    class="indigo darken-1 headline "
                    primary-title
                  >
                    <v-toolbar-title class="white--text"
                      >Registers</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <Button
                      colore_icona="primary"
                      mdi_icona="mdi-plus"
                      testo_tooltip="Aggiornare"
                      v-on:buttonClick="createRegister(interf)"
                    />
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item._edit`]="{ item }">
                  <Button
                    colore_icona="primary"
                    mdi_icona="mdi-pencil"
                    testo_tooltip="Aggiornare"
                    v-on:buttonClick="openRegister(item)"
                  />
                </template>

                <template v-slot:[`item._delete`]="{ item }">
                  <Button
                    colore_icona="error"
                    mdi_icona="mdi-window-close"
                    testo_tooltip="Cancellare"
                    v-on:buttonClick="deleteRegister(item, interf.registers)"
                  />
                </template>
              </v-data-table>
            </v-col>
            <v-col>
              <v-data-table
                :items="interf.upload"
                :headers="upload_header"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    dense
                    class="indigo darken-1 headline "
                    primary-title
                  >
                    <v-toolbar-title class="white--text"
                      >Uploads</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <Button
                      colore_icona="primary"
                      mdi_icona="mdi-plus"
                      testo_tooltip="Aggiornare"
                      v-on:buttonClick="createUpload(interf.upload)"
                    />
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item._edit`]="{ item }">
                  <Button
                    colore_icona="primary"
                    mdi_icona="mdi-pencil"
                    testo_tooltip="Aggiornare"
                    v-on:buttonClick="openUpload(item)"
                  />
                </template>

                <template v-slot:[`item._delete`]="{ item }">
                  <Button
                    colore_icona="error"
                    mdi_icona="mdi-window-close"
                    testo_tooltip="Cancellare"
                    v-on:buttonClick="deleteUpload(item, interf.upload)"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import DynamicInput from "../../../components/PRODUCTION/Impostazioni/DynamicInput.vue";

export default {
  components: {
    Button,
    DynamicInput
  },
  props: {
    id_macchina: String
  },
  data() {
    return {
      upload_header: [
        {
          text: "",
          value: "_edit",
          sortable: false
        },
        {
          text: "Upload Name",
          value: "upload_name",
          sortable: false
        },
        {
          text: "Template",
          value: "template",
          sortable: false
        },
        {
          text: "Variables",
          value: "variables",
          sortable: false
        },
        {
          text: "",
          value: "_delete",
          sortable: false
        }
      ],
      upload_items: [],
      selected_item: {
        register: null,
        variable: null,
        alarm: null
      },
      expanded: {
        register: [],
        variable: [],
        alarm: []
      },
      alert: {
        icona: null,
        messaggio: null,
        mostra: false,
        loader: false
      },
      NETWORK: null,
      id_gateway: null,
      gateway_lista: [],
      register_dialog: false,
      variable_dialog: false,
      alarm_dialog: false,
      upload_dialog: false,
      register_dialog_template: {},
      variable_dialog_template: {},
      alarm_dialog_template: {},
      register_template: {},
      variable_template: {},
      upload_dialog_template: {},
      alarm_template: {},
      configuration: null,
      counter: {
        interface: 0,
        register: 0,
        variable: 0,
        alarm: 0
      },
      interfaces: [],
      interface_constants: [
        "interface_id",
        "transport_layer",
        "data_layer",
        "registers",
        "upload"
      ]
    };
  },
  mounted() {
    this.getconfigJson();
    this.getInterfaces();
    this.getCustomerDevices();
    this.NETWORK = this.$store.state.Network;
  },
  methods: {
    // Update Template
    updateTemplate(interface_index) {
      if (interface_index != undefined) {
        const interf = this.interfaces[interface_index];
        const transport_layer_index = interf.transport_layer;
        const data_layer_index = interf.data_layer;

        this.register_template = {};
        const registers = this.configuration.transport_layers[
          transport_layer_index
        ].register_fields;

        for (let index = 0; index < registers.length; index++) {
          this.register_template[registers[index].field] = registers[index];
        }

        this.variable_template = {};
        let variables = this.configuration.transport_layers[
          transport_layer_index
        ].variable_fields;
        for (let index = 0; index < variables.length; index++) {
          this.variable_template[variables[index].field] = variables[index];
        }

        variables = this.configuration.data_layers[data_layer_index]
          .variable_fields;
        for (let index = 0; index < variables.length; index++) {
          this.variable_template[variables[index].field] = variables[index];
        }
        Object.assign(this.counter, { register: 0, variable: 0 });
        for (let i = 0; i < interf.registers.length; i++) {
          const register = interf.registers[i];
          this.counter.register = Math.max(this.counter.register, register._id);
          for (let j = 0; j < register.variables.length; j++) {
            const variable = register.variables[j];
            this.counter.variable = Math.max(
              this.counter.variable,
              variable._id
            );
          }
        }
        console.log("Updated counters:", this.counter);
      }
    },
    // Filter Variables
    filterVariables(interf) {
      const result = [];
      interf.registers &&
        interf.registers.map((register) => {
          register.variables.map((variable) => {
            result.push(variable._id);
          });
        });
      return result;
    },
    // set current item
    setCurrentItem(item, field) {
      if (item.value == true) {
        this.selected_item[field] = item.item;
      }
    },
    // reset Object
    resetObject(object) {
      const keys = Object.keys(object);
      for (let index = 0; index < keys.length; index++) {
        delete object[keys[index]];
      }
    },
    // Create new Register
    createRegister(interf) {
      const register = {};
      const fields = Object.keys(this.register_template);
      for (let index = 0; index < fields.length; index++) {
        register[fields[index]] = null;
      }
      register._id = ++this.counter.register;
      register.variables = [];
      console.log(`register`, register);
      interf.registers.push(register);
    },
    // Create Variable of selected Register
    createVariable(item) {
      const variable = {};

      const fields = Object.keys(this.variable_template);
      for (let index = 0; index < fields.length; index++) {
        variable[fields[index]] = null;
      }
      variable._id = ++this.counter.variable;
      variable.alarms = [];
      item.variables.push(variable);
    },
    // Create Alarm
    createAlarm(item) {
      const alarm = {};
      const fields = Object.keys(this.alarm_template);
      for (let index = 0; index < fields.length; index++) {
        alarm[fields[index]] = null;
      }
      alarm._id = ++this.counter.alarm;
      item.alarms.push(alarm);
    },
    // create upload
    createUpload(uploads) {
      uploads.push({
        upload_name: "",
        variables: [],
        template: ""
      });
    },

    // save Register
    saveRegister() {
      const template = this.register_dialog_template;
      const register = template._reference;
      delete template._reference;
      Object.assign(register, template);
      this.register_dialog = false;
    },
    // Save Variable
    saveVariable() {
      const template = this.variable_dialog_template;
      const variable = template._reference;
      delete template._reference;
      Object.assign(variable, template);
      this.variable_dialog = false;
    },
    // Save Alarm
    saveAlarm() {
      const template = this.alarm_dialog_template;
      console.log(`template`, template);
      const alarm = template._reference;
      delete template._reference;
      Object.assign(alarm, template);
      this.alarm_dialog = false;
    },
    // save upload
    saveUpload() {
      const template = this.upload_dialog_template;
      const upload = template._reference;
      delete template._reference;
      Object.assign(upload, template);
      this.upload_dialog = false;
    },
    // openRegister
    openRegister(register) {
      console.log(`register`, register);
      this.resetObject(this.register_dialog_template);
      const fields = Object.keys(this.register_template);
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];
        this.register_dialog_template[field] = register[field];
      }
      this.register_dialog_template._reference = register;
      this.register_dialog = true;
    },
    openVariable(variable) {
      console.log(`variable`, variable);
      this.resetObject(this.variable_dialog_template);
      const fields = Object.keys(this.variable_template);
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];
        this.variable_dialog_template[field] = variable[field];
      }
      this.variable_dialog_template._reference = variable;
      this.variable_dialog = true;
    },
    openAlarm(alarm) {
      console.log(`alarm`, alarm);
      this.resetObject(this.alarm_dialog_template);
      const fields = Object.keys(this.alarm_template);
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];
        this.alarm_dialog_template[field] = alarm[field];
      }
      this.alarm_dialog_template._reference = alarm;
      this.alarm_dialog = true;
    },
    // open upload
    openUpload(item) {
      Object.assign(this.upload_dialog_template, item);
      this.upload_dialog_template._reference = item;
      this.upload_dialog = true;
    },

    // deleteRegister
    deleteRegister(item, registers) {
      registers.splice(registers.indexOf(item), 1);
    },
    // deleteRegister
    deleteVariable(item) {
      this.selected_item.register.variables.splice(
        this.selected_item.register.variables.indexOf(item),
        1
      );
    },
    deleteAlarm(item) {
      this.selected_item.variable.alarms.splice(
        this.selected_item.variable.alarms.indexOf(item),
        1
      );
    },
    deleteUpload(upload, uploads) {
      uploads.splice(uploads.indexOf(upload), 1);
    },
    // get Headers
    getHeader(template) {
      const keywords = ["variables", "alarms"];
      const result = [{ text: "", value: "_edit" }];
      const fields = Object.keys(template);
      // console.log(`fields`, fields);
      for (let index = 0; index < fields.length; index++) {
        const field = template[fields[index]];

        if (keywords.indexOf(field.field) < 0) {
          result.push({
            text: field.name,
            value: field.field
          });
        }
      }
      result.push({ text: "", value: "_delete" });
      // console.log(`result`, result);
      return result;
    },
    //  Reset Expanded Fields
    resetExpanded(interf) {
      const fields = this.configuration.interface_fields;
      for (let index = 0; index < fields.length; index++) {
        interf[fields[index].field] = null;
      }
    },

    // Interface Constants fields expansions
    interfaceExpansion() {
      const fields = this.configuration.interface_fields;
      for (let index = 0; index < fields.length; index++) {
        this.interface_constants.push(fields[index].field);
      }
    },
    // CHange Data Layer
    changeDataLayer(ev, interf) {
      if (interf.data_layer != null) {
        const data_layer = this.configuration.data_layers[interf.data_layer];
        const variables = data_layer.variable_fields;
        for (let index = 0; index < variables.length; index++) {
          delete this.variable_template[variables[index].field];
        }
      }
      const variables = ev.variable_fields;
      for (let index = 0; index < variables.length; index++) {
        this.variable_template[variables[index].field] = variables[index];
      }
      interf.data_layer = this.configuration.data_layers.indexOf(ev);
      this.resetArray(interf.registers);
      this.resetArray(interf.upload);
    },
    // Utility Function for reset Array
    resetArray(array) {
      while (array.length > 0) {
        array.splice(0, 1);
      }
    },
    // To Get Transport layer
    getTransport(interf) {
      if (interf.transport_layer == null) {
        return null;
      }
      return this.configuration.transport_layers[interf.transport_layer];
    },

    //  To get Data layer
    getData(interf) {
      if (interf.data_layer == null) {
        return null;
      }
      return this.configuration.data_layers[interf.data_layer];
    },
    // Changed Transport Layer
    changeTransport(ev, interf) {
      interf.transport_layer = this.configuration.transport_layers.indexOf(ev);
      interf.data_layer = null;
      this.resetArray(interf.registers);
      this.resetArray(interf.upload);
      this.resetArray(interf._supported_data_layers);
      const data_layers = this.configuration.data_layers;
      const supported = ev.supported_data_layers;
      for (let index = 0; index < supported.length; index++) {
        interf._supported_data_layers.push(data_layers[supported[index]]);
      }
      const fields = Object.keys(interf);
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];
        if (
          field.charAt(0) != "_" &&
          this.interface_constants.indexOf(field) < 0
        ) {
          delete interf[field];
        }
      }
      for (let index = 0; index < ev.interface_fields.length; index++) {
        interf[ev.interface_fields[index].field] = null;
      }
      this.register_template = {};
      const registers = ev.register_fields;
      for (let index = 0; index < registers.length; index++) {
        this.register_template[registers[index].field] = registers[index];
      }

      this.variable_template = {};
      const variables = ev.variable_fields;
      for (let index = 0; index < variables.length; index++) {
        this.variable_template[variables[index].field] = variables[index];
      }
    },
    // Create new interface
    CreateNewInterface() {
      this.counter.interface++;
      const interf = {
        interface_id: this.counter.interface,
        transport_layer: null,
        data_layer: null,
        registers: [],
        upload: [],
        _supported_data_layers: []
      };
      this.resetExpanded(interf);
      this.interfaces.push(interf);
    },
    //  Delete Interface
    deleteInterface(interf) {
      this.interfaces.splice(this.interfaces.indexOf(interf), 1);
    },

    getconfigJson() {
      this.alert.loader = true;
      this.alert.mostra = true;
      let finale = {
        controller: "quickconnext",
        method: "POST",
        richiesta: {
          action: "getconfigschema",
          token: localStorage.getItem("user_token")
        }
      };
      this.$store.dispatch("api", finale).then((res) => {
        this.configuration = res.data;
        this.interfaceExpansion();
        this.alarm_template = {};
        const fields = this.configuration.alarm_fields;

        for (let index = 0; index < fields.length; index++) {
          const field = fields[index];
          this.alarm_template[field.field] = field;
        }
        this.alert.loader = false;
        this.alert.mostra = false;
      });
    },
    getInterfaces() {
      console.log(`this.Macchine_id`, this.Macchine_id);
      let finale = {
        controller: "quickconnext",
        method: "POST",
        richiesta: {
          action: "getmachineconfiguration",
          token: localStorage.getItem("user_token"),
          machine_id: this.id_macchina
        }
      };

      this.$store.dispatch("api", finale).then((res) => {
        this.id_gateway = res.data[0].id_gateway;
        try {
          const configuration = JSON.parse(
            decodeURIComponent(escape(atob(res.data[0].conf_4_0)))
          );
          this.interfaces = configuration;
        } catch {
          this.interfaces = [];
        }
        let max = 0;
        for (let i = 0; i < this.interfaces.length; i++) {
          max = Math.max(this.interfaces[i].interface_id);
        }
        this.counter.interface = max;
      });
    },

    getCustomerDevices() {
      let finale = {
        controller: "quickconnext",
        method: "POST",
        richiesta: {
          action: "getcustomerdevices",
          token: localStorage.getItem("user_token")
        }
      };

      this.$store.dispatch("api", finale).then((res) => {
        this.gateway_lista = res.data;
      });
    },

    saveInterface() {
      this.alert.loader = true;
      // this.alert.mostra = true;
      let interface_Lista = btoa(
        unescape(encodeURIComponent(JSON.stringify(this.interfaces)))
      );

      const that = this;
      const request = {
        controller: "quickconnext",
        response: { parse: false },
        showdialog: false,
        body: {
          action: "setmachineconfiguration",
          token: localStorage.getItem("user_token"),
          machine_id: this.id_macchina,
          id_gateway: this.id_gateway,
          conf_4_0: interface_Lista
        },
        method: "PUT",
        on: {
          load: function() {
            Object.assign(that.alert, {
              icona: "mdi-check",
              messaggio: "Salvato con successo!",
              mostra: true,
              loader: false
            });
          },
          error: function(response) {
            response = response.target;
            Object.assign(that.alert, {
              icona: "mdi-alert-box",
              messaggio: response.responseText,
              mostra: true,
              loader: false
            });
          }
        }
      };
      this.NETWORK.AjaxRequest(request);
    }
  }
};
</script>
