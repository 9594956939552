<template>
  <div class="text-center">
    <v-dialog
      content-class="vehDialog"
      overflow="hidden"
      persistent
      v-model="dialog"
      width="unset"
    >
      <template v-slot:activator="{ on }">
        <Button
          colore_icona="primary"
          idForm="MACCHINA_SCHEDA"
          idOggetto="MODIFICA_MACCHINA_SCHEDA"
          mdi_icona="mdi-pencil"
          testo_tooltip="Aggiornare"
          v-on="on"
          v-on:buttonClick="editciclimacchina"
        />
      </template>
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text">Aggiorna Scheda</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            idForm="MACCHINA_SCHEDA"
            idOggetto="SALVA_MACCHINA_SCHEDA"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="
              dialog = false;
              onUpdate(item.id_macchina_scheda_ordinaria);
            "
          >
          </Button>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" @click="dialog = false" small>
                <v-icon depressed color="error">mdi-window-close</v-icon>
              </v-btn>
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>
        <v-container>
          <v-form>
            <v-container>
              <v-row class="mt-n1">
                <v-col class="d-flex shrink py-0" sm="2" cols="4" md="2">
                  <TextField
                    idForm="MACCHINA_SCHEDA"
                    idOggetto="MODIFICA_SCHEDA_NUM_OPERAZIONE"
                    label_input="Num Operazione"
                    palceholder_input="Num Operazione"
                    :vmodel="num_operazione"
                    :min_input="0"
                    type_input="number"
                    v-on:changeText="(val) => (this.num_operazione = val)"
                  />
                </v-col>
                <v-col class="shrink py-0" sm="2" cols="4" md="2">
                  <v-select
                    v-model="id_tipo_operazione"
                    :items="tipoOperazione"
                    item-text="des"
                    item-value="id_tabella"
                    label="Tipo Operazione"
                    placeholder="Tipo Operazione"
                    required
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="4" class="py-0" sm="2" md="2">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="dumFormat"
                        label="D.U.M"
                        prepend-icon="event"
                        class="shrink"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data_ultima_manutenzione"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex shrink py-0" sm="2" cols="4" md="2">
                  <v-select
                    v-model="id_calcolo"
                    :items="calcoloList"
                    item-text="des"
                    label="Calcolo"
                    item-value="id_tabella"
                    placeholder="Calcolo"
                    return-object
                    required
                  ></v-select>
                </v-col>
                <v-col class="d-flex shrink py-0" sm="2" cols="4" md="2">
                  <v-select
                    v-model="id_metodo_calcolo"
                    :items="metodoCacoloList"
                    item-text="des"
                    item-value="id_tabella"
                    label="Metodo Calcolo"
                    return-object
                    placeholder="Metodo Calcolo"
                    required
                  ></v-select>
                </v-col>
                <v-col class="shrink py-0" sm="2" cols="4" md="2">
                  <TextField
                    idForm="MACCHINA_SCHEDA"
                    idOggetto="MODIFICA_SCHEDA_ORE_GIORNI"
                    label_input="Ore giorni"
                    palceholder_input="Ore giorni"
                    :vmodel="ore_giorni"
                    :min_input="0"
                    type_input="number"
                    v-on:changeText="(val) => (this.ore_giorni = val)"
                  />
                </v-col>
                <v-col class="shrink d-flex py-0" sm="3" cols="4" md="2">
                  <v-select
                    class="shrink"
                    v-model="id_responsabile"
                    :items="responsabile_list_Id"
                    item-text="des"
                    item-value="id_tabella"
                    return-object
                    label="Responsabiles"
                    placeholder="Responsabiles"
                    required
                  ></v-select>
                </v-col>
                <v-col class="shrink d-flex py-0" sm="5" cols="4" md="9">
                  <TextField
                    idForm="MACCHINA_SCHEDA"
                    idOggetto="MODIFICA_SCHEDA_DES"
                    label_input="Descrizione Scheda"
                    palceholder_input="Descrizione Scheda"
                    :vmodel="des_scheda"
                    v-on:changeText="(val) => (this.des_scheda = val)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
        <v-card-actions class="mt-n10">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField.vue";
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
// import config from '../../../../public/config'
export default {
  components: {
    TextField,
    Button,
  },
  props: ["item"],
  // Variables Required for Updating Scheda Modal
  data() {
    return {
      calcoloList: "",
      metodoCacoloList: "",
      tipoOperazione: "",
      id_macchina_scheda_ordinaria: this.item.id_macchina_scheda_ordinaria,
      responsabile_list_Id: [],
      responsabile_list: [],
      id_macchina: this.item.id_macchina,
      num_operazione: this.item.num_operazione,
      id_tipo_operazione: this.item.id_tipo_operazione,
      scadenza: this.item.scadenza,
      ore_giorni: this.item.ore_giorni,
      dialog: false,
      id_calcolo: this.item.id_calcolo,
      id_metodo_calcolo: this.item.id_metodo_calcolo,
      id_responsabile: this.item.id_responsabile,
      des_scheda: this.item.des_scheda,
      id_utente: this.item.id_utente,
      data_ultima_manutenzione: moment(
        this.item.data_ultima_manutenzione
      ).format("YYYY-MM-DD"),
      validita: this.item.validita,
      date_ins: moment(this.item.date_ins).format("YYYY-MM-DD"),
      date_mod: this.date_modified,
      menu2: false,
    };
  },
  methods: {
    editciclimacchina() {
      this.dialog = true;
    },
    // To UPdate an existing Scheda
    onUpdate(id) {
      let schedadata = {
        data_ultima_manutenzione: this.data_ultima_manutenzione,
        des_scheda: this.des_scheda,
        id_macchina: this.id_macchina,
        id_calcolo:
          typeof this.id_calcolo === "object"
            ? this.id_calcolo.id_tabella
            : this.id_calcolo,
        id_metodo_calcolo:
          typeof this.id_metodo_calcolo == "object"
            ? this.id_metodo_calcolo.id_tabella
            : this.id_metodo_calcolo,
        id_responsabile:
          typeof this.id_responsabile == "object"
            ? this.id_responsabile.id_tabella
            : this.id_responsabile,
        id_tipo_operazione:
          typeof this.id_tipo_operazione == "object"
            ? this.id_tipo_operazione.id_tabella
            : this.id_tipo_operazione,
        des_calcolo:
          typeof this.id_calcolo == "object"
            ? this.id_calcolo.des
            : this.item.des_calcolo,
        des_metodo_calcolo:
          typeof this.id_metodo_calcolo == "object"
            ? this.id_metodo_calcolo.des
            : this.item.des_metodo_calcolo,
        des_responsabile:
          typeof this.id_responsabile == "object"
            ? this.id_responsabile.des
            : this.item.des_responsabile,
        des_tipo_operazione:
          typeof this.id_tipo_operazione == "object"
            ? this.id_tipo_operazione.des
            : this.item.des_tipo_operazione,
        num_operazione: this.num_operazione,
        ore_giorni: this.ore_giorni,
        scadenza: this.scadenza,
        validita: this.validita,
        date_ins: this.date_ins,
        date_mod: this.date_mod,
        id_macchina_scheda_ordinaria: id,
      };
      let dataarray = [];
      dataarray.push(schedadata);
      var finale = {
        controller: "Manutenzione",
        id_utente: this.id_utente,
        method: "PUT",
        richiesta: {
          action: "updatescheda",
          token: localStorage.getItem("user_token"),
          tabella: dataarray,
        },
      };
      this.$store.dispatch("api", finale);
      this.$emit("aggiornaScheda");
    },
  },
  computed: {
    dumFormat() {
      return this.data_ultima_manutenzione
        ? moment(this.data_ultima_manutenzione).format("DD/MM/YYYY")
        : "";
    },
  },
  mounted() {
    // To populate the select list in the update form
    let req_data = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo:
          "'MANUTENZIONE_TIPO_OPERAZIONE','MANUTENZIONE_METODO_CALCOLO','MANUTENZIONE_CALCOLO','MANUTENZIONE_RESPONSABILE'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", req_data).then((res) => {
      this.metodoCacoloList = res.data.MANUTENZIONE_METODO_CALCOLO;
      this.tipoOperazione = res.data.MANUTENZIONE_TIPO_OPERAZIONE;
      this.calcoloList = res.data.MANUTENZIONE_CALCOLO;
      this.responsabile_list_Id = res.data.MANUTENZIONE_RESPONSABILE;
    });
  },
  watch: {
    item() {
      const or = this.item;
      this[or] = !this[or];
    },
  },
};
</script>
<style lang="scss" scoped>
.vehDialog {
  overflow: hidden;
}
</style>
