<template>
  <div>
    <v-container fluid class="my-0 py-0">
      <v-card :elevation="8">
        <TabellaRichieste
          :allow_cod_richiesta_custom="false"
          :allow_doppia_descrizione="false"
          :back_as_landing_page="false"
          :checkbox="'interno'"
          :go_back_visible="false"
          :toolbar_style="'quickproduction'"
        />
      </v-card>
    </v-container>

    <!-- Snackbar -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
// import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField";
import TabellaRichieste from "@/components/MANUTENZIONE/TabellaRichieste.vue";
import Request from "@/classes/Request.js";

export default {
  name: "VehicleDetail",
  //because u pass schedas and no one refresh this i think ok i have to do the same for update modal?
  props: [],
  // SubComponents of Scheda
  components: {
    // TextField,
    TabellaRichieste,
  },
  data() {
    return {
      // Variabili d'appoggio
      data_ultima_manutenzione: "",
      dumFormat: "",
      id_calcolo: -1,
      id_metodo_calcolo: -1,
      des_scheda: "",
      id_responsabile: -1,
      id_tipo_operazione: -1,
      item: {},
      items: this.schedas,
      macchina_detail: this.schedas,
      num_operazione: -1,
      ore_giorni: null,
      progressBar: true,
      richiesta_sel: new Request(),
      search: "",
      updateSchedaModal: false,

      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      calcolo_list: [],
      headers: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Gestisci Interventi",
          sortable: false,
          value: "gestisci_interventi",
        },
        {
          text: "Cod. Richiesta",
          sortable: true,
          value: "cod_richiesta",
        },
        {
          text: "Richiedente",
          sortable: true,
          value: "des_richiedente",
        },
        {
          text: "Data Richiesta",
          sortable: true,
          value: "data_richiesta",
        },
        {
          text: "Urgenza",
          sortable: false,
          value: "des_urgenza",
        },
        {
          text: "Sede",
          sortable: true,
          value: "des_sede",
        },
        {
          text: "Struttura",
          sortable: true,
          value: "des_struttura",
        },
        {
          text: "Area",
          sortable: false,
          value: "des_area",
        },
        {
          text: "Zona",
          sortable: false,
          value: "des_zona",
        },
        {
          text: "Macchinario",
          sortable: false,
          value: "des_macchinario",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        { text: "Stato", sortable: false, value: "des_stato" },
      ],
      Items: [],
      items_richieste: [],
      items_richieste_visualizzate: [],
      metodo_calcolo_list: [],
      responsabile_list: [],
      responsabile_list_Id: [],
      tipo_operazione_list: [],
    };
  },

  watch: {
    macchinaDetail() {
      const m = this.schedas;
      this[m] = !this[m];
      return;
    },
    macchina_detail() {
      const m = this.macchinaDetail;
      this[m] = !this[m];
    },
  },
  methods: {
    clickModificaDialog(item) {
      console.log("ITEM:", item);
      this.richiesta_sel = Object.assign({}, item);
      this.dialog_richieste_visible = true;
    },
    getitem(val) {
      this.item = val;
      this.updateSchedaModal = true;
    },
    getListaRichieste() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlistarichieste",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          id_profilo_utente: localStorage.id_profilo,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.items_richieste = res.data.result;
          this.items_richieste.forEach((elem) => {
            elem.data_richiesta = elem.data_richiesta.substring(0, 10);
          });
          // this.items_richieste_visualizzate = res.data.result;
          this.items = res.data.result;
        }
      });
    },
    // To Delete a single Scheda
    deleteitem(item) {
      item.validita = 0;
      let dataarray = [item];
      let del = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "updatescheda",
          token: localStorage.getItem("user_token"),
          tabella: dataarray,
        },
      };
      this.$store.dispatch("api", del).then(() => {
        this.snackbar = true;
        this.snackbar_icon = "mdi-check";
        this.snackbar_background = "success";
        this.snackbar_text = "Modifica effettuata con successo!";
        this.snackbar_text_color = "white";
      });
      let i = this.items.findIndex(
        (it) =>
          it.id_macchina_scheda_ordinaria === item.id_macchina_scheda_ordinaria
      );
      this.items.splice(i, 1);
    },
    // To Go back macchona list
    goMacchina() {
      this.$router.push("/home/production/macchina");
    },
    aggiornaScheda(val) {
      if (val) {
        this.snackbar = true;
        this.snackbar_icon = "mdi-check";
        this.snackbar_background = "success";
        this.snackbar_text = "Modifica effettuata con successo!";
        this.snackbar_text_color = "white";
      }
      //something like this ok got it thanks when i finish i will send you the dist ok
      this.schedas.push(val);
    },
    //  To update a single Scheda
    updateScheda(val) {
      this.calcolo_list.map((el) => {
        if (el.id_tabella === val.id_calcolo) {
          val.des_calcolo = el.des;
          return val;
        }
      });
      this.metodo_calcolo_list.map((el) => {
        if (el.id_tabella === val.id_metodo_calcolo) {
          val.des_metodo_calcolo = el.des;
          return val;
        }
      });
      this.tipo_operazione_list.map((el) => {
        if (el.id_tabella === val.id_tipo_operazione) {
          val.des_tipo_operazione = el.des;
          return val;
        }
      });
      this.responsabile_list_Id.map((el) => {
        if (el.id_tabella === val.id_responsabile) {
          val.des_responsabile = el.des;
          return val;
        }
      });
      let i = this.schedas.findIndex(
        (el) =>
          el.id_macchina_scheda_ordinaria == val.id_macchina_scheda_ordinaria
      );
      this.schedas.splice(i, 1, val);
      this.snackbar = true;
      this.snackbar_icon = "mdi-check";
      this.snackbar_background = "success";
      this.snackbar_text = "Modifica effettuata con successo!";
      this.snackbar_text_color = "white";
    },
  },
  mounted() {
    this.getListaRichieste();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.vehDialog {
  overflow-x: hidden !important;
}
</style>
